import React, { useEffect, useState } from 'react';
import { Section, Col, Row, Wrapper } from '../../../../components/content';
import { ProductProvider, useProducts } from '../../../../context/products';
import { PERMISSIONS } from '../../../../utils/permissions';
import { ProductBreadcrumb } from './product-breadcrumb/ProductBreadcrumb';
import { ProductGallery } from './product-gallery/ProductGallery';
import { ProductInfo } from './product-info/ProductInfo';
import { ProductOptions } from './product-options/ProductOptions';
import { ProductTags } from './product-tags/ProductTags';
// import { ProductType } from './product-type/ProductType';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import Loading from '../../../../components/loading/Loading';
// import ProductUpcCode from './product-upccode/ProductUpcCode';
import LanguageSelector from '../../../../helpers/LanguageSelector';
import { useTranslation } from 'react-i18next';


const CreateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useProducts();




  useEffect(() => {
    setProductEdit(false);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const CreateProductSubmit = (props) => {
  const { form, onCancel, onSubmit, productCategoriesLoading } = useProducts();

  const { language } = props;

  const { t } = useTranslation();

  

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">{t('cancel')}</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onSubmit(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`)} className="btn btn-primary" type="button">
            <span className="text">{t('createProduct')}</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const CreateProduct = () => {

  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('sr');

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  useEffect(() => {
    handleLanguageChange(language);
  }, []);


  return (
    <ProductProvider>
      <ProductBreadcrumb />
      <Section className="section__product section__product--wrapper section__product--create">
        <CreateProductLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <LanguageSelector onSelectLanguage={handleLanguageChange} />  
                <ProductInfo className="product__card--info" language={language} />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <ProductGallery className="product__card--gallery" language={language} />
                {/* <ProductType className="product__card--type" /> */}
                <ProductOptions className="product__card--options" language={language} />
                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <ProductTags className="product__card--tags" language={language} />
              </Wrapper>
            </Col>
          </Row>
        </CreateProductLoader>

        <CreateProductSubmit language={language} />
      </Section>
    </ProductProvider>
  );
};

export default CreateProduct;
