import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';
import { useTranslation } from 'react-i18next';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;

  const { t } = useTranslation();
  const grocery = [
    {
      _id: '',
      label: t('Store'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: t('Attributes'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: t('Products'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/products.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-products',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: t('Categories'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`,
          group: 'webshop',
          type: 'anchor',
          icon: '/assets/icons/categories.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-categories',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
      ],
    },
    {
      _id: '',
      label: t('Orders'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/orders.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-orders',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('Gallery'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('Content Editor'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/editor-content.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [
        // {
        //   _id: '',
        //   label: t('Hero baners'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.baners}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        {
          _id: '',
          label: t('Terms of service'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.terms}`,
          group: 'users',
          type: 'anchor',
          icon: '/assets/icons/blog.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-roles',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        {
          _id: '',
          label: t('Privacy policy'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.privacy}`,
          group: 'users',
          type: 'anchor',
          icon: '/assets/icons/blog.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-roles',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        // users questions table view
        {
          _id: '',
          label: t('Users questions'),
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.questions}`,
          group: 'users',
          type: 'anchor',
          icon: '/assets/icons/blog.svg',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-roles',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
          onClick: function() {
            onHide(false);
          },
          children: [],
        },
        // {
        //   _id: '',
        //   label: t('Delivery'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.delivery}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        // {
        //   _id: '',
        //   label: t('Cookies'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.cookies}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        // {
        //   _id: '',
        //   label: t('About us'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.aboutUs}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        // {
        //   _id: '',
        //   label: t('Blog'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        // {
        //   _id: '',
        //   label: t('Create blog'),
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: false,
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
        // {
        //   _id: '',
        //   label: 'FAQ',
        //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.faq}`,
        //   group: 'users',
        //   type: 'anchor',
        //   icon: '/assets/icons/blog.svg',
        //   expanded: false,
        //   protected: false,
        //   dataCy: 'sidebar-grocery-roles',
        //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
        //   onClick: function() {
        //     onHide(false);
        //   },
        //   children: [],
        // },
      ],
    },
    // {
    //   _id: '',
    //   label: t('Roles'),
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`,
    //   group: 'users',
    //   type: 'anchor',
    //   icon: '/assets/icons/roles.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-roles',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
    //   onClick: function() {
    //     onHide(false);
    //   },
    //   children: [],
    // },
    {
      _id: '',
      label: 'Zaposleni',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/customers.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-customers',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    // {
    //   _id: '',
    //   label: 'Employees',
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`,
    //   group: 'users',
    //   type: 'anchor',
    //   icon: '/assets/icons/employees.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-employees',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
    //   onClick: function() {
    //     onHide(false);
    //   },
    //   children: [],
    // },
    // {
    //   _id: '',
    //   label: 'Activity Log',
    //   path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`,
    //   group: 'users',
    //   type: 'anchor',
    //   icon: '/assets/icons/activity.svg',
    //   expanded: false,
    //   protected: false,
    //   dataCy: 'sidebar-grocery-activity-log',
    //   visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity),
    //   onClick: function() {
    //     onHide(false);
    //   },
    //   children: [],
    // },
    {
      _id: '',
      label: t('Profile'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: t('Logout'),
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

export { groceryMenuItems };
