import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const SingleStatusDeactivate = ({ singleDeactiveProps, deactiveSingleModalFormRef, handleOnSubmit, setToggleSingleDeactive, deactivateBtnLoader }) => {
  return (
    <Modal {...singleDeactiveProps} className="sidebar__modal--center" title="">
      <form
        name="singleDeactive"
        ref={deactiveSingleModalFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'status',
            state: singleDeactiveProps,
          })
        }
        className="ant-form ant-form-horizontal"
      >
        <h2 className="text-center mb-4">Are you sure you want to {singleDeactiveProps.getId.status === 'ACTIVE' ? 'deactivate' : 'activate'} the selected item ?</h2>
        <div className="form-group form-group-modal mb-4">
          {!deactivateBtnLoader ? (
            <button data-cy="modal-bulk-deactive-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Yes</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-link-outline">
              <span className="text"> {singleDeactiveProps.getId.status === 'ACTIVE' ? 'Deactivating...' : 'Activating...'}</span>
            </button>
          )}

          <button data-cy="modal-bulk-deactive-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleSingleDeactive(false)}>
            <span className="text">No</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
