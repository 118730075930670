import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { Categories } from './categories/Categories';
import { Description } from './description/Description';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../../components/loading/Loading';

export const ProductInfo = (props) => {
  const { form, onChange, formError } = useProducts();
  const { className, language } = props;


  const { t } = useTranslation();

  return (
    <Wrapper className={`product__card ${className}`}>
      {
        language === 'en' ? (
          <>
          <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>{t('Product Info')}</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>{t('Product Name')}:</b>
              </label>
              <input value={form.productNameEn || ''} onChange={onChange} name="productNameEn" placeholder={t('productName')} type="text" id="productName" className={`input ${formError.productNameEn ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productNameEn ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productSlug">
                <b>{t('Product Slug')}:</b>
              </label>

              <input value={form.productSlugEn || ''} onChange={onChange} name="productSlugEn" placeholder={t('productSlug')} type="text" id="productSlug" className={`input ${formError.productSlugEn ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productSlugEn ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <span className="label">
                <b>{t('Visibility')}:</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input checked={form.productFeatured || false} onChange={onChange} name="productFeatured" type="checkbox" id="productFeatured" className="checkbox" />
                  <label className="label" htmlFor="productFeatured">
                    <b>{t('Featured Product')}:</b>
                  </label>
                </div>

                <div className="item">
                  <input checked={form.productVisible || false} onChange={onChange} name="productVisible" type="checkbox" id="productVisible" className="checkbox" />
                  <label className="label" htmlFor="productVisible">
                    <b>{t('Visible In Store')}:</b>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <Categories label={t("Product Categories")} className="product-categories" />
            </div>

            <div className="form-group">
              <Description label={t("Product Content")} className="product-description" language={language} />
            </div>
          </form>
        </main>
      </div>
          </>
        ) : language === 'sr' ? (
<>
             <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>{t('Product Info')}</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>{t('Product Name')}:</b>
              </label>
              <input value={form.productNameSr || ''} onChange={onChange} name="productNameSr" placeholder={t('productName')} type="text" id="productName" className={`input ${formError.productNameSr ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productNameSr ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productSlug">
                <b>{t('Product Slug')}:</b>
              </label>

              <input value={form.productSlugSr || ''} onChange={onChange} name="productSlugSr" placeholder={t('productSlug')} type="text" id="productSlug" className={`input ${formError.productSlugSr ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productSlugSr ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <span className="label">
                <b>{t('Visibility')}:</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input checked={form.productFeatured || false} onChange={onChange} name="productFeatured" type="checkbox" id="productFeatured" className="checkbox" />
                  <label className="label" htmlFor="productFeatured">
                    <b>{t('Featured Product')}:</b>
                  </label>
                </div>

                <div className="item">
                  <input checked={form.productVisible || false} onChange={onChange} name="productVisible" type="checkbox" id="productVisible" className="checkbox" />
                  <label className="label" htmlFor="productVisible">
                    <b>{t('Visible In Store')}:</b>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <Categories label={t("Product Categories")} className="product-categories" />
            </div>

            <div className="form-group">
              <Description label={t("Product Content")} className="product-description" language={language} />
            </div>
          </form>
        </main>
      </div>
          </>
        ) : (
          <>
           <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>{t('Product Info')}</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>{t('Product Name')}:</b>
              </label>
              <input value={form.productNameHu || ''} onChange={onChange} name="productNameHu" placeholder={t('productName')} type="text" id="productName" className={`input ${formError.productNameHu ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productNameHu ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productSlug">
                <b>{t('Product Slug')}:</b>
              </label>

              <input value={form.productSlugHu || ''} onChange={onChange} name="productSlugHu" placeholder={t('productSlug')} type="text" id="productSlug" className={`input ${formError.productSlugHu ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.productSlugHu ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <span className="label">
                <b>{t('Visibility')}:</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input checked={form.productFeatured || false} onChange={onChange} name="productFeatured" type="checkbox" id="productFeatured" className="checkbox" />
                  <label className="label" htmlFor="productFeatured">
                    <b>{t('Featured Product')}:</b>
                  </label>
                </div>

                <div className="item">
                  <input checked={form.productVisible || false} onChange={onChange} name="productVisible" type="checkbox" id="productVisible" className="checkbox" />
                  <label className="label" htmlFor="productVisible">
                    <b>{t('Visible In Store')}:</b>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <Categories label={t("Product Categories")} className="product-categories" />
            </div>

            <div className="form-group">
              <Description label={t("Product Content")} className="product-description" language={language} />
            </div>
          </form>
        </main>
      </div>
          </>
        )
      }
    </Wrapper>
  );
};
