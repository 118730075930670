import { Input, Form, Button, notification } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const UpdateFaqForm = ({ data }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const initialValues = data;

  const [answer, setAnswer] = useState();

  const getTextValue = (value) => {
    if (value !== answer) {
      setAnswer(value);
    }
  };

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const onFinish = async (values) => {
    values.answer = answer;
    await Axios.put(`${SERVER_URL}/faq/${data._id}`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'FAQ is updated.' });

    navigate('/admin/grocery/faq');
  };

  return (
    <div className="title-container">
      <Form initialValues={initialValues} onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p>UPDATE FAQ</p>
        </div>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <Link to={'/admin/grocery/faq'} style={{ color: 'black' }}>
            ALL FAQS
          </Link>
        </div>

        <Form.Item name="question" label="Question">
          <Input type="text" placeholder="question" className="input-blog-page" />
        </Form.Item>

        <div className="tiny-blog-page">
          <Form.Item name="answer" label="Answer">
            <Editor
              apiKey={apiKey}
              onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
              init={{
                height: 600,
                menubar: true,
                plugins: 'table',
              }}
            />
          </Form.Item>
        </div>
        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
          UPDATE FAQ
        </Button>
      </Form>
    </div>
  );
};

export default UpdateFaqForm;
