import { Input, Form, Button, notification } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';

const CreateFaq = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const [answer, setAnswer] = useState();

  const getTextValue = (value) => {
    if (value !== answer) {
      setAnswer(value);
    }
  };

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const onFinish = async (values) => {
    values.answer = answer;
    await Axios.post(`${SERVER_URL}/faqs`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'FAQ is created.' });

    navigate('/admin/grocery/faq');
  };

  const customStyle = {
    color: 'white',
  };

  return (
    <div className="title-container">
      <Form onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p style={customStyle} >ADD NEW FAQ</p>
        </div>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <Link to={'/admin/grocery/faq'} style={{ color: 'white' }}>
            ALL FAQS
          </Link>
        </div>
        <Form.Item name="question" label="Question">
          <Input type="text" placeholder="question" className="input-blog-page" />
        </Form.Item>

        <div className="tiny-blog-page">
          <p>Answer</p>
          <Editor
            apiKey={apiKey}
            onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
            init={{
              height: 600,
              menubar: true,
              plugins: 'table',
            }}
          />
        </div>
        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
          Add FAQ
        </Button>
      </Form>
    </div>
  );
};

export default CreateFaq;
