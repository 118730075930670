import { useProducts } from '../../../../../../context/products';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const General = (props) => {
  const { onChange, formError, form, setTabs } = useProducts();

  const { language } = props

  const { t } = useTranslation();

  useEffect(() => {
    if (formError.productRegularPrice.empty || formError.productRegularPrice.format || formError.productSalePrice.bigger || formError.productSalePrice.format) {
      setTabs(1);
    }
  }, [formError.productSalePrice, formError.productRegularPrice]);

  return (
    <form name="product-general" className="form">
      <div className="form-group">
        <label className="label" htmlFor="productRegularPrice">
          <b>{t('regularPrice')}:</b>
        </label>

        <input onChange={onChange} name="productRegularPrice" type="text" value={form.productRegularPrice} placeholder={t('regularPrice')} id="productRegularPrice" inputMode="numeric" className={`input ${formError.productRegularPrice?.empty || formError.productRegularPrice?.format ? 'error' : ''}`} />

        <p data-cy="" className={`error__onblur ${formError.productRegularPrice?.empty ? 'error' : ''}`}>
          * This field is required!
        </p>
        <p data-cy="" className={`error__onblur ${formError.productRegularPrice?.format ? 'error' : ''}`}>
          * This field must have none or two decimal points!
        </p>
      </div>

      <div className="form-group">
        <label className="label" htmlFor="productSalePrice">
          <b>{t('salePrice')}:</b>
        </label>

        <input onChange={onChange} name="productSalePrice" type="text" placeholder={t('salePrice')} id="productSalePrice" inputMode="numeric" className={`input ${formError.productSalePrice?.bigger || formError.productSalePrice?.format ? 'error' : ''}`} value={form.productSalePrice} />
        {formError.productSalePrice.format ? (
          <p data-cy="" className="error__onblur error">
            * This field must have none or two decimal points!
          </p>
        ) : (
          formError.productSalePrice.bigger && (
            <p data-cy="" className="error__onblur error">
              * Sale price must be lower than regular price!
            </p>
          )
        )}
      </div>
    </form>
  );
};
