import React, { useEffect } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { QuestionProvider, useQuestions } from '../../../../context/questions';
import Loading from '../../../../components/loading/Loading';
import { QuestionInfo } from '../question-info/QuestionInfo';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import { PERMISSIONS } from '../../../../utils/permissions';

const UpdateQuestionLoader = (props) => {
  const { children } = props;

  // console.log('children', children);
  const { setQuestionEdit } = useQuestions();

  useEffect(() => {
    setQuestionEdit(true);
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

const UpdateQuestionSubmit = () => {
  const { form, onCancel, onUpdate } = useQuestions();

  return (
    <footer className="question-save">
      <div className="actions">

          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">Cancel</span>
          </button>

          <button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.questions}`)} className="btn btn-primary" type="button">
            <span className="text">Update Question</span>
          </button>
      </div>
    </footer>
  );
};

const UpdateQuestion = () => {
  return (
    <QuestionProvider>
      <Section className="section__product section__product--wrapper section__update-product">
        <UpdateQuestionLoader>
          <Row>
            <Col>
              <Wrapper>
                <QuestionInfo className="product__card--info" />
              </Wrapper>
            </Col>
          </Row>
        </UpdateQuestionLoader>
        <UpdateQuestionSubmit />
      </Section>
    </QuestionProvider>
  );
};

export default UpdateQuestion;
