import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { TabsPanel } from './tabs-panel/TabsPanel';
// Tabs component
import { Inventory } from './inventory/Inventory';
import { General } from './general/General';
import { Attributes } from './attributes/Attributes';
import { Variations } from './variations/Variations';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ProductOptions = (props) => {
  const { form, tabs, setTabs } = useProducts();
  const { className, language } = props;

  let tabsContent = [];

  const { t } = useTranslation();

  if (form.productType === 'simple') {
    tabsContent = [
      { id: 'dfjhsdfhshfsdjfsdfjkk', name: t('inventory'), element: <Inventory  language={language} /> },
      { id: 'fsdfsdkfdfdkkdkdkdkkd', name: t('general'), element: <General language={language} /> },
      { id: 'dklfgsdjkfsdkfwpdkwkd', name: t('attributes'), element: <Attributes  language={language}/> }
    ];
  }

  // if (form.productType === 'variables') {
  //   tabsContent = [
  //     { id: 'dfjhsdfhshfsdjfsdfjkk', name: 'Inventory', element: <Inventory /> },
  //     { id: 'dklfgsdjkfsdkfwpdkwkd', name: 'Attributes', element: <Attributes /> },
  //     { id: 'dffdfdsfdfsdfdioweifo', name: 'Variations', element: <Variations {...props} /> }
  //   ];
  // }

  useEffect(() => {
    if (form.productType) {
      setTabs(0);
    }
  }, [form.productType, setTabs]);

  return (
    <Wrapper className={`product__card ${className}`}>
      <TabsPanel {...props} tabsContent={tabsContent} tabs={tabs} setTabs={setTabs} />
    </Wrapper>
  );
};
