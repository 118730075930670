import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Button, notification } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { formatTime, formatDate } from '../../../helpers/helpers';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';

const Table = (props) => {
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await Axios.get(`${SERVER_URL}/blogs`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    setData(res.data.items);
  };

  const handleDelete = async (id) => {
    await Axios.delete(`${SERVER_URL}/blog/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'Blog is deleted.' });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <>
      <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`} className="btn btn-primary pl-4 pr-4 d-flex items-center">
        <span className="text d-flex items-center align-center">Dodaj blog</span>
      </Link>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Naslov</th>
            <th>Meta Naslov</th>
            <th>Link</th>
            <th>Akcije</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr key={item._id} className={item.status === 'Ordered' ? 'status-ordered' : ''}>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.title}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.metaTitle}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.url}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateBlog}/${item._id}`} className="link btn-actions link-details">
                        <span style={{ cursor: 'pointer' }} className="icon d-flex">
                          <img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
                        </span>
                      </Link>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleDelete(item._id);
                        }}
                        className="icon d-flex"
                      >
                        <img alt="DELETE" src="/assets/icons/delete.svg" style={{ display: 'block' }} />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
