import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import { generateId, slugify, slugifyReplaceAll } from '../../helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

const ProductContext = createContext(null);

const ProductProvider = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState(0);

  // Gallery
  const [toggleGallery, setToggleGallery] = useState(false);
  const [toggleGalleryId, setToggleGalleryId] = useState({
    index: '',
    image: {
      _id: '',
      __v: 0,
      createdAt: '',
      updatedAt: '',
      forMobile: false,
      localPath: '',
      originalName: '',
      url: ''
    }
  });

  const [gallerySelect, setGallerySelect] = useState('singleselect');

  const [tabsGallery, setTabsGallery] = useState(0);
  const [refetchGallery, setRefetchGallery] = useState(false);

  // SINGLE SLECT
  const [select, setSelect] = useState('');
  const onChangeItem = (event) => {
    const { value } = event.target;
    setSelect(value);
  };

  useEffect(() => {
    if (toggleGalleryId && toggleGalleryId.image !== '') {
      // setSelect(toggleGalleryId.image);
    }
  }, [toggleGalleryId]);

  // MULTI SELECT
  const [selected, setSelected] = useState([]);
  const onChangeItems = (event) => {
    const { value, checked } = event.target;

    setSelected((prev) => {
      return [...prev, value];
    });

    if (!checked) {
      setSelected(selected.filter((item) => item !== value));
    }
  };

  const [selectImage, setSelectImage] = useState({
    _id: '',
    __v: 0,
    createdAt: '',
    updatedAt: '',
    forMobile: false,
    localPath: '',
    originalName: '',
    url: ''
  });
  const handleOnSelect = (obj) => {
    setSelectImage(obj);
  };

  const [selectImagesId, setSelectImagesId] = useState([]);
  const handleOnSelected = (arr) => {
    setSelectImagesId(arr);
  };

  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedAttributeUnit, setSelectedAttributeUnit] = useState([]);
  const [toggleRefetchSelectAttr, setToggleRefetchSelectAttr] = useState(false);
  const [loadingAttributeUnit, setLoadingAttributeUnit] = useState(false);
  const [combineVariants, setCombineVariants] = useState([]);
  const [variationForm, setVariationForm] = useState({
    productVariation: 'custom'
  });

  const [selectedUnits, setSelectedUnits] = useState([]);
  const [elements, setElements] = useState([]);

  // const productEligible = [
  //   { id: 'fdfdskfdfkdjkfkaa', name: 'FSA', value: 'FSA' },
  //   { id: 'fjkdsdjfdjfhsddfh', name: 'WIC', value: 'WIC' },
  //   { id: 'fjsdfsdjfdsfjdjjd', name: 'EBT', value: 'EBT' }
  // ];

  // Form
  const [form, setForm] = useState({
    productNameEn: '',
    productNameSr: '',
    productNameHu: '',
    productSlugSr: '',
    productSlugEn: '',
    productSlugHu: '',
    productVisible: false,
    productFeatured: false,
    // productEligible: [],
    productCategory: '',
    productContentSr: '',
    productContentEn: '',
    productContentHu: '',
    productGallery: [],
    // productWeight: '',
    // productWeightVariant: '',
    productRegularPrice: '',
    // productRegularPriceVariant: '',
    productSalePrice: '',
    // productSalePriceVariant: '',
    // productVariations: [],
    // productSku: '',
    productTags: [],
    productAttributesUnit: [],
    productType: 'simple',
    // productVariation: 'none',
    productManage: false,
    productStockQuantity: '',
    // productUpcCode: ''
  });

  const [formError, setFormError] = useState({
    productNameEn: null,
    productSlugEn: null,
    productNameSr: null,
    productSlugSr: null,
    productNameHu: null,
    productSlugHu: null,
    productCategory: null,
    // productWeight: { empty: null, format: null },
    productRegularPrice: { empty: null, format: null },
    productSalePrice: { bigger: null, format: null },
    // productRegularPriceVariant: { empty: null, format: null },
    // productWeightVariant: { empty: null, format: null },
    // productVariations: []
  });

  const formBase = {
    _id: generateId(),
    variantImage: {
      _id: null,
      __v: 0,
      createdAt: '',
      updatedAt: '',
      forMobile: false,
      localPath: '',
      originalName: '',
      url: ''
    },
    // variantSku: '',
    // variantRegularPrice: '',
    // variantSalePrice: '',
    // variantQuantity: '',
    // variantWeight: '',
    // variantType: 'generated',
    units: []
  };

  const generateNewFormBase = () => {
    return { ...formBase, _id: generateId() };
  };

  const [formFields, setFormFields] = useState([]);

  // On Change
  const onChange = (event) => {
    const { name, value, checked } = event.target;

    setForm((prevState) => {
      if (name === 'productRegularPrice' || name === 'productSalePrice' || name === 'productRegularPriceVariant' || name === 'productWeightVariant') {
        const priceValue = value
          .replace(/[^\d\.]/g, '')
          .replace(/\./, 'x')
          .replace(/\./g, '')
          .replace(/x/, '.');
        return { ...prevState, [name]: priceValue };
      }
      if (name === 'productStockQuantity') {
        return { ...prevState, [name]: value.replace(/[^0-9]/g, '') };
      }

      if (value === 'on') {
        return { ...prevState, [name]: checked };
      } else {
        if (name === 'productNameEn') {
          const slug = slugify(value);
          return { ...prevState, [name]: value, productSlugEn: slug };
        } else if (name === 'productNameSr') {
          const slug = slugify(value);
          return { ...prevState, [name]: value, productSlugSr: slug };
        } else if (name === 'productNameHu') {
          const slug = slugify(value);
          return { ...prevState, [name]: value, productSlugHu: slug };
        } else if (name === 'productSlugEn') {
          const slug = slugifyReplaceAll(value);
          return { ...prevState, [name]: slug };
        } else if (name === 'productSlugSr') {
          const slug = slugifyReplaceAll(value);
          return { ...prevState, [name]: slug };
        } else if (name === 'productSlugHu') {
          const slug = slugifyReplaceAll(value);
          return { ...prevState, [name]: slug };
        } else {
          return { ...prevState, [name]: value };
        }
      }
    });
  };

  // const handleChange = (e) => {
  //   const regex = /^[0-9\b]+$/;
  //   if (e.target.value === "" || regex.test(e.target.value)) {
  //     setVal(e.target.value);
  //   }
  // };

  // On Valid
  const onValid = useCallback(
    (form) => {
      let checkIsValid = false;

      // validate input fields for simple product
      let checkProductNameEn = false;
      let checkProductSlugEn = false;
      let checkProductNameSr = false;
      let checkProductSlugSr = false;
      let checkProductNameHu = false;
      let checkProductSlugHu = false;
      let checkProductCategory = false;
      let checkProductWeight = false;
      let checkSimpleProductRegularPrice = false;
      let checkProductSalePrice = false;

      const pattern = /^\$?\d+(\.\d{2})?$/;
      const pattern2 = /^\$?\d+(\.\d{1})?$/;

      if (form.productNameEn.length > 0) {
        checkProductNameEn = true;
        setFormError((prevState) => {
          return { ...prevState, productNameEn: false };
        });
      } if (form.productNameSr.length > 0) {
        checkProductNameSr = true;
        setFormError((prevState) => {
          return { ...prevState, productNameSr: false };
        });
      } if (form.productNameHu.length > 0) {
        checkProductNameHu = true;
        setFormError((prevState) => {
          return { ...prevState, productNameHu: false };
        });
      } if (form.productNameEn.length <= 0) {
        checkProductNameEn = false;
        setFormError((prevState) => {
          return { ...prevState, productNameEn: true };
        });
      } if (form.productNameSr.length <= 0) {
        checkProductNameSr = false;
        setFormError((prevState) => {
          return { ...prevState, productNameSr: true };
        });
      } 
      if (form.productNameHu.length <= 0) {
        checkProductNameHu = false;
        setFormError((prevState) => {
          return { ...prevState, productNameHu: true };
        });
      }

      if (form.productSlugEn.length > 0) {
        checkProductSlugEn = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugEn: false };
        });
      } if (form.productSlugSr.length > 0) {
        checkProductSlugSr = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugSr: false };
        });
      } if (form.productSlugHu.length > 0) {
        checkProductSlugHu = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugHu: false };
        });
      }
      if (form.productSlugEn.length <= 0) {
        checkProductSlugEn = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugEn: true };
        });
      } if (form.productSlugSr.length <= 0) {
        checkProductSlugSr = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugSr: true };
        });
      } if (form.productSlugHu.length <= 0) {
        checkProductSlugHu = true;
        setFormError((prevState) => {
          return { ...prevState, productSlugHu: true };
        });
      }

      if (form.productCategory && form.productCategory?.length > 0) {
        checkProductCategory = true;
        setFormError((prevState) => {
          return { ...prevState, productCategory: false };
        });
      } else {
        checkProductCategory = false;
        setFormError((prevState) => {
          return { ...prevState, productCategory: true };
        });
      }

      // if (form.productType === 'simple' && form.productWeight) {
      //   // check if form's weight isn't empty
      //   checkProductWeight = true;
      //   setFormError((prevState) => {
      //     return {
      //       ...prevState,
      //       productWeight: {
      //         ...prevState.productWeight,
      //         empty: false
      //       }
      //     };
      //   });
      //   // check if form's weight matches the pattern
      //   if (pattern2.test(form.productWeight)) {
      //     checkProductWeight = true;
      //     setFormError((prevState) => {
      //       return {
      //         ...prevState,
      //         productWeight: {
      //           ...prevState.productWeight,
      //           format: false
      //         }
      //       };
      //     });
      //   } else {
      //     checkProductWeight = false;
      //     setFormError((prevState) => {
      //       return {
      //         ...prevState,
      //         productWeight: {
      //           ...prevState.productWeight,
      //           format: true
      //         }
      //       };
      //     });
      //   }
      // } else {
      //   checkProductWeight = false;
      //   setFormError((prevState) => {
      //     return {
      //       ...prevState,
      //       productWeight: {
      //         ...prevState.productWeight,
      //         empty: true
      //       }
      //     };
      //   });
      // }

      if (form.productType === 'simple' && form.productRegularPrice) {
        // check if form's regular price isn't empty
        checkSimpleProductRegularPrice = true;
        setFormError((prevState) => {
          return {
            ...prevState,
            productRegularPrice: {
              ...prevState.productRegularPrice,
              empty: false
            }
          };
        });
        // check if form's regular price matches the pattern
        if (pattern.test(form.productRegularPrice)) {
          checkSimpleProductRegularPrice = true;
          setFormError((prevState) => {
            return {
              ...prevState,
              productRegularPrice: {
                ...prevState.productRegularPrice,
                format: false
              }
            };
          });
          // check if sale price isn't empty
          if (form.productSalePrice) {
            // check if sale price matches the pattern
            if (pattern.test(form.productSalePrice)) {
              checkProductSalePrice = true;
              setFormError((prevState) => {
                return {
                  ...prevState,
                  productSalePrice: {
                    ...prevState.productSalePrice,
                    format: false
                  }
                };
              });
              if (Number(form.productRegularPrice) > Number(form.productSalePrice)) {
                checkProductSalePrice = true;
                setFormError((prevState) => {
                  return {
                    ...prevState,
                    productSalePrice: {
                      ...prevState.productSalePrice,
                      bigger: false
                    }
                  };
                });
              } else {
                checkProductSalePrice = false;
                setFormError((prevState) => {
                  return {
                    ...prevState,
                    productSalePrice: {
                      ...prevState.productSalePrice,
                      bigger: true
                    }
                  };
                });
              }
            } else {
              checkProductSalePrice = false;
              setFormError((prevState) => {
                return {
                  ...prevState,
                  productSalePrice: {
                    ...prevState.productSalePrice,
                    format: true
                  }
                };
              });
            }
          } else {
            checkProductSalePrice = true;
            setFormError((prevState) => {
              return {
                ...prevState,
                productSalePrice: {
                  format: false,
                  bigger: false
                }
              };
            });
          }
        } else {
          checkSimpleProductRegularPrice = false;
          setFormError((prevState) => {
            return {
              ...prevState,
              productRegularPrice: {
                ...prevState.productRegularPrice,
                format: true
              }
            };
          });
        }
      } else {
        checkSimpleProductRegularPrice = false;
        setFormError((prevState) => {
          return {
            ...prevState,
            productRegularPrice: {
              ...prevState.productRegularPrice,
              empty: true
            }
          };
        });
      }

      if (form.productType === 'simple' && checkProductNameSr && checkProductNameEn && checkProductNameHu && checkProductSlugEn && checkProductSlugSr && checkProductSlugHu && checkProductCategory && checkSimpleProductRegularPrice) {
        checkIsValid = true;
      }

      return checkIsValid;
    },
    [formError, form]
  );

  // On Cancel
  const onCancel = () => {
    window.location.reload();
  };

  const compareLocalAndGlobal = (global, local, variant) => {
    if (global === local) {
      return true;
    } else {
      if (local === variant) {
        if (local !== '' && variant !== '') {
          return true;
        } else {
          return false;
        }
      } else if (global === variant) {
        return true;
      } else {
        return false;
      }
    }
  };

  const compareVariationsObjects = useCallback(() => {
    // loop through each variation
    const values = [];

    // return true if the formFields is empty to not waste time checking the variations
    if (formFields.length > 0) {
      // check if the formFields is equal to variations length
      if (formFields.length === form.productVariations.length) {
        // loop through each form field
        for (let i = 0; i < formFields.length; i++) {
          const variableFormField = formFields[i];

          if (form.productVariations && form.productVariations.length > 0) {
            const variation = form.productVariations[i];
            // check if the SKU and Stock match
            if (variation.SKU === variableFormField.variantSku && variation.Stock === variableFormField.variantQuantity) {
              // check if the SalePrice match
              if (variation.SalePrice === variableFormField.variantSalePrice) {
                const formFieldsUnits = variableFormField.units.map((unit) => Object.values(unit)[0]);

                if (JSON.stringify(variation.Units) === JSON.stringify(formFieldsUnits)) {
                  if (!!variation.Image === !!variableFormField.variantImage || !!variation.Image === !!variableFormField.variantImage?._id) {
                    const regularPriceResult = {
                      globalPrice: form.productRegularPriceVariant,
                      localPrice: variableFormField.variantRegularPrice,
                      variantPrice: variation.RegularPrice
                    };

                    const { globalPrice, localPrice, variantPrice } = regularPriceResult;

                    const prices = compareLocalAndGlobal(globalPrice, localPrice, variantPrice);
                    if (prices) {
                      const weights = compareLocalAndGlobal(form.productWeightVariant, variableFormField.variantWeight, variation.weight);
                      if (weights) {
                        values.push(true);
                      } else {
                        values.push(false);
                      }
                    } else {
                      values.push(false);
                    }
                  } else {
                    values.push(false);
                  }
                } else {
                  values.push(false);
                }
              } else {
                values.push(false);
              }
            } else {
              values.push(false);
            }
          } else {
            values.push(false);
          }
        }
        // formFields has different length, immediatly update
      } else {
        values.push(false);
      }
      // formFields is empty so don't do anything
    } else {
      values.push(true);
    }

    // loop through each form field

    return values.some((value) => !value);
  }, [formFields, form, form.productRegularPriceVariant, form.productVariations]);

  const [savedForm, setSavedForm] = useState({});

  useEffect(() => {
    setSavedForm(form);
  }, [form]);

  // On Submit
  const onSubmit = useCallback(
    async (path) => {
      // SIMPLE PRODUCT
      if (form.productType === 'simple') {
        const attributesData = form.productAttributesUnit.map((item) => {
          const selectedUnits = item.selectedUnits.map((item) => {
            return item._id;
          });
          return {
            Attribute: item._id,
            values: selectedUnits
          };
        });

        const isValidSimpleProduct = true;

        // console.log('isValidSimpleProduct', isValidSimpleProduct);

        if (isValidSimpleProduct) {
          let simplePayload = {
            titleEn: form.productNameEn,
            slugEn: form.productSlugEn,
            contentEn: form.productContentEn,
            titleSr: form.productNameSr,
            slugSr: form.productSlugSr,
            contentSr: form.productContentSr,
            titleHu: form.productNameHu,
            slugHu: form.productSlugHu,
            contentHu: form.productContentHu,
            category: form.productCategory,
            department: PERMISSIONS.grocery,
            productType: 'simple_product',
            gallery: form.productGallery,
            SKU: form.productSku,
            attributes: attributesData,
            isFeatured: form.productFeatured,
            visible: form.productVisible,
            tags: form.productTags,
            // eligible: form.productEligible,
            quantity: +form.productStockQuantity,
            SalePrice: form.productSalePrice?.toString(),
            RegularPrice: form.productRegularPrice?.toString(),
            featureImage: form.productGallery[0] ? form.productGallery[0] : null,
            upccode: form.productUpcCode,
            weight: +form.productWeight
          };

          const { token } = user;
          try {
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.getProducts}`,
                { ...simplePayload },
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${token}` }
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  // redirect
                  navigate(`/${path}`);

                  // notification
                  notification.success({
                    message: 'Successfully created a simple product.',
                    placement: 'bottomLeft'
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {});
          } catch (err) {
            console.error(err);
          }
        }
      }

      // VARIABLE PRODUCT
      // if (form.productType === 'variables') {
      //   let savedForm = form;
      //   if (formFields.length > 0 && compareVariationsObjects()) {
      //     if (window.confirm('You have unsaved changes. Do you want to save them?')) {
      //       savedForm = saveCustomVariations();
      //     } else {
      //       return;
      //     }
      //   }
      //   const attributesData = savedForm.productAttributesUnit.map((item) => {
      //     const selectedUnits = item.selectedUnits.map((item) => {
      //       return item._id;
      //     });
      //     return {
      //       Attribute: item._id,
      //       values: selectedUnits
      //     };
      //   });

      //   let isValidVariableProduct = onValid(savedForm);

      //   const updatedVariations = savedForm.productVariations.map((item) => {
      //     const set = new Set(item.Units);

      //     return {
      //       ...item,
      //       RegularPrice: item.RegularPrice?.toString(),
      //       SalePrice: item.SalePrice?.toString(),
      //       Image: item.Image ? item.Image : null,
      //       Units: [...set],
      //       weight: +item.weight
      //     };
      //   });

      //   if (isValidVariableProduct) {
      //     let variableProduct = {
      //       title: savedForm.productName,
      //       slug: savedForm.productSlug,
      //       content: savedForm.productContent,
      //       category: savedForm.productCategory,
      //       department: PERMISSIONS.grocery,
      //       productType: 'variable_product',
      //       gallery: savedForm.productGallery,
      //       SKU: savedForm.productSku,
      //       attributes: attributesData,
      //       isFeatured: savedForm.productFeatured,
      //       visible: savedForm.productVisible,
      //       tags: savedForm.productTags,
      //       eligible: savedForm.productEligible,
      //       quantity: +savedForm.productStockQuantity,
      //       Product_Variations: updatedVariations,
      //       featureImage: savedForm.productGallery[0] ? savedForm.productGallery[0] : null,
      //       upccode: savedForm.productUpcCode
      //     };

      //     const { token } = user;
      //     try {
      //       await axios
      //         .post(
      //           `${SERVER_URL}/${AXIOS_API_CALL.getProducts}`,
      //           { ...variableProduct },
      //           {
      //             withCredentials: false,
      //             headers: { Authorization: `Bearer ${token}` }
      //           }
      //         )
      //         .then((res) => {
      //           if (res.status === 200) {
      //             // redirect
      //             navigate(`/${path}`);

      //             // notification
      //             notification.success({
      //               message: 'Successfully created a variable product.',
      //               placement: 'bottomLeft'
      //             });
      //           }
      //         })
      //         .catch((err) => {
      //           console.error(err);
      //         })
      //         .finally(() => {});
      //     } catch (err) {
      //       console.error(err);
      //     }
      //   }
      // }
    },
    [form, formFields, form.productVariations]
  );

  // On Submit
  const onUpdate = useCallback(
    async (path) => {
      // SIMPLE PRODUCT
      if (form.productType === 'simple') {
        const attributesData = form.productAttributesUnit.map((item) => {
          const selectedUnits = item.selectedUnits.map((item) => {
            return item._id;
          });
          return {
            Attribute: item._id,
            values: selectedUnits
          };
        });

        const isValidSimpleProduct = onValid(form);

        if (isValidSimpleProduct) {
          let simplePayload = {
            titleSr: form.productNameSr,
            titleEn: form.productNameEn,
            titleHu: form.productNameHu,
            slugEn: form.productSlugEn,
            slugSr: form.productSlugSr,
            slugHu: form.productSlugHu,
            contentSr: form.productContentSr,
            contentEn: form.productContentEn,
            contentHu: form.productContentHu,
            category: form.productCategory,
            productType: 'simple_product',
            gallery: form.productGallery,
            SKU: form.productSku,
            attributes: attributesData,
            isFeatured: form.productFeatured,
            visible: form.productVisible,
            tags: form.productTags,
            // eligible: form.productEligible,
            quantity: +form.productStockQuantity,
            SalePrice: form.productSalePrice?.toString(),
            RegularPrice: form.productRegularPrice?.toString(),
            featureImage: form.productGallery[0] ? form.productGallery[0] : null,
            upccode: form.productUpcCode,
            weight: +form.productWeight
          };

          const { token } = user;
          try {
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.updateProduct}/${productId}`,
                { ...simplePayload },
                {
                  withCredentials: false,
                  headers: {
                    Authorization: `Bearer ${token}`,
                    department: PERMISSIONS.grocery
                  }
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  // redirect
                  navigate(`/${path}`);

                  // notification
                  notification.success({
                    message: 'Successfully updated a simple product.',
                    placement: 'bottomLeft'
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {});
          } catch (err) {
            console.error(err);
          }
        }
      }
    },
    [form, formFields]
  );

  // BLOCK
  const onChangeUnits = (props) => {
    const { action, rowId, rowData } = props;

    switch (action) {
      case 'add':
        const currentIdxAdd = selectedAttribute.findIndex((item) => item.key === rowId);

        setSelectedAttribute((prevState) => {
          const newData = prevState;
          newData[currentIdxAdd].selectedUnits = rowData;
          return [...prevState];
        });
        break;
      case 'remove':
        const currentIdxRemove = selectedAttribute.findIndex((item) => item.key === rowId);

        setSelectedAttribute((prevState) => {
          const newData = prevState;
          newData[currentIdxRemove].selectedUnits = rowData;
          return [...prevState];
        });
        break;
      default:
    }
  };

  // Get Combine variants
  const generateCombineOutput = (originalData, dataIndex, optionIndex) => {
    let option = {};
    let result = [];
    let subResult = [];

    option = {
      _id: originalData[dataIndex]._id,
      name: originalData[dataIndex].name,
      units: originalData[dataIndex].selectedUnits[optionIndex]
    };

    if (dataIndex === originalData.length - 1) {
      result = {
        isLast: true,
        units: [option]
      };

      return result;
    }

    for (let i = 0; i < originalData[dataIndex + 1].selectedUnits.length; i++) {
      subResult = generateCombineOutput(originalData, dataIndex + 1, i);

      if (subResult.isLast) {
        subResult.units.unshift(option);
        result.push(subResult.units);
      } else {
        result = result.concat(subResult);
      }
    }

    if (!subResult.isLast) {
      for (let j = 0; j < result.length; j++) {
        result[j].unshift(option);
      }
    }

    return result;
  };

  const getCombine = (originalData) => {
    if (originalData && originalData.length === 0) {
      return;
    }

    let result = [];
    let output = [];

    for (let i = 0; i < originalData[0].selectedUnits.length; i++) {
      const subResult = generateCombineOutput(originalData, 0, i);

      if (subResult.isLast) {
        result.push(subResult.units);
      } else {
        result = result.concat(subResult);
      }
    }

    for (let i = 0; i < result.length; i++) {
      output.push({
        variant_id: Math.floor(1000 + Math.random() * 9000),
        options: result[i]
      });
    }

    return output;
  };

  useEffect(() => {
    if (selectedAttributeUnit && selectedAttributeUnit.length > 0) {
      const filteredVariants = [];
      for (let i = 0; i < selectedAttributeUnit.length; i++) {
        if (selectedAttributeUnit[i].selectedUnits.length > 0) {
          filteredVariants.push(selectedAttributeUnit[i]);
        }
      }
      const combineVariants = getCombine(filteredVariants) || [];
      setCombineVariants(combineVariants);
    }
  }, [selectedAttributeUnit]);

  const generateFormFields = () => {
    if (combineVariants && combineVariants.length > 0) {
      if (!formFields || formFields.length === 0) {
        const newFormFields = combineVariants.map((item) => {
          return generateNewFormBase();
        });
        newFormFields.map((item, i) => {
          item.units = combineVariants[i].options.map((opt) => {
            return {
              [opt.units.value]: opt.units._id
            };
          });
        });

        setFormFields(newFormFields);
      } else if (formFields && formFields.length > 0) {
        const allUnitCombinations = combineVariants.map((item) => {
          return item.options.map((opt) => {
            return { [opt.units.value]: opt.units._id };
          });
        });

        const filteredFormFields = formFields.filter((field) => {
          if (field.variantType === 'generated') {
            return allUnitCombinations.some((combination) => {
              return combination.every((unit) => {
                const [key] = Object.keys(unit);
                return field.units.some((fieldUnit) => {
                  return fieldUnit.hasOwnProperty(key) && fieldUnit[key] === unit[key];
                });
              });
            });
          } else {
            return field;
          }
        });

        const missingCombinations = allUnitCombinations.filter((combination) => {
          return !filteredFormFields.some((field) => {
            if (field.variantType === 'generated') {
              return field.units.every((unit) => {
                const key = Object.keys(unit)[0];
                return combination.some((option) => {
                  const fieldKey = Object.keys(option)[0];
                  return fieldKey === key && option[fieldKey] === unit[key];
                });
              });
            }
          });
        });

        const newFormFields = missingCombinations.map((units) => {
          return {
            ...generateNewFormBase(),
            units
          };
        });

        setFormFields([...filteredFormFields, ...newFormFields]);
      } else {
        setFormFields(formFields);
      }
    } else {
      setFormFields([]);
    }
  };

  const handleOnSave = (data) => {
    setLoadingAttributeUnit(true);
    setTimeout(() => setLoadingAttributeUnit(false), 700);
    setSelectedAttributeUnit(data);
    setForm((prevState) => {
      return { ...prevState, productAttributesUnit: data };
    });
  };

  const handleOnReset = () => {
    setSelectedAttribute([]);
    setSelectedAttributeUnit([]);
  };

  // Categories
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [productCategoriesLoading, setProductCategoriesLoading] = useState(true);
  const [productCategoriesRefetch, setProductCategoriesRefetch] = useState(false);

  const getProductCategories = useCallback(async () => {
    const { token } = user;
    try {
      setProductCategoriesLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.categoriesGetAll}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProductCategoriesData(res.data);
          }
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setProductCategoriesLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setProductCategoriesLoading(false);
    }
  }, [user, setProductCategoriesLoading, setProductCategoriesData]);

  useEffect(() => {
    getProductCategories();
  }, [getProductCategories, setProductCategoriesRefetch]);

  // Attributes
  const [productAttributesData, setProductAttributesData] = useState([]);
  const [productAttributesLoading, setProductAttributesLoading] = useState(false);
  const [productAttributesRefetch, setProductAttributesRefetch] = useState(false);

  const getProductAttributes = useCallback(async () => {
    const { token } = user;

    try {
      setProductAttributesLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.attributes}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setProductAttributesData(res.data.items);
          }
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setProductAttributesLoading(false);
          }, 700)
        );
    } catch (err) {}
  }, [user, setProductAttributesData, setProductAttributesLoading]);

  useEffect(() => {
    getProductAttributes();
  }, [getProductAttributes, setProductAttributesRefetch]);

  const [reviewImages, setReviewImages] = useState([]);

  const getImagesByIds = useCallback(
    async (images) => {
      const { token } = user;

      try {
        const res = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.productGalleryImages}`,
          { department: PERMISSIONS.grocery, images: images },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (res.status === 200) {
          setReviewImages(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [user]
  );

  const getAttributeFromUnits = useCallback(
    async (unit) => {
      const { token } = user;

      try {
        const res = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.units}/${unit}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        });

        if (res.status === 200) {
          return res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    [user]
  );

  const asyncMap = async (array, asyncCallback) => {
    const results = [];
    for (const item of array) {
      try {
        if (item?.Units && item?.Units.length > 0) {
          for (const unit of item.Units) {
            const res = await asyncCallback(unit._id);
            results.push(res);
          }
        } else if (item?.units && item?.units.length > 0) {
          for (const unit of item.units) {
            const id = Object.values(unit)[0];
            const res = await asyncCallback(id);

            results.push(res);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    return results;
  };

  const onRemoveSelected = (id) => {
    const newSelected = selectImagesId.filter((item) => item !== id);
    const newReviewImages = reviewImages.filter((item) => item._id !== id);
    setSelected(newSelected);
    setSelectImagesId(newSelected);
    setReviewImages(newReviewImages);
  };

  const setFeaturedImage = useCallback(
    (selectedImageId) => {
      const index = reviewImages.findIndex((image) => image._id === selectedImageId);
      if (index !== -1) {
        const newSelectImages = [...reviewImages];
        const selectedImage = newSelectImages.splice(index, 1)[0];
        newSelectImages.unshift(selectedImage);
        const newSelectedImagesIds = newSelectImages.map((item) => item._id);
        setSelected(newSelectedImagesIds);
        setSelectImagesId(newSelectedImagesIds);
        setReviewImages(newSelectImages);
        setForm((prevState) => {
          return { ...prevState, productGallery: newSelectedImagesIds };
        });
        return newSelectImages;
      }
      return reviewImages;
    },
    [reviewImages]
  );

  const [productData, setProductData] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [productRefetch, setProductRefetch] = useState(false);
  const [productEdit, setProductEdit] = useState(false);

  const { id: productId } = useParams();

  const getProduct = useCallback(async () => {
    const { token } = user;

    try {
      setProductLoading(true);
      await axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getProduct}/${productId}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async (res) => {
          if (res.status === 200) {
            const { productGlobalData, variantData } = res.data.message;

            try {
              setProductAttributesLoading(true);
              await axios
                .post(
                  `${SERVER_URL}/${AXIOS_API_CALL.attributes}`,
                  { department: PERMISSIONS.grocery },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` }
                  }
                )
                .then(async (res1) => {
                  if (res1.status === 200) {
                    setProductAttributesData(res1.data.items);

                    const attributes = productGlobalData?.attributes?.map((attr, index) => {
                      return {
                        units: res1.data.items[index].units,
                        _id: attr?.Attribute[0]?._id,
                        name: attr.Attribute[0].name,
                        selectedUnits: attr.values.map((val) => ({
                          _id: val._id,
                          value: val.value
                        })),
                        key: Math.floor(Math.random() * 10000)
                      };
                    });

                    // TODO: Handle form fields
                    if (productGlobalData.productType === 'simple_product') {
                      setProductData(productGlobalData);
                      setForm({
                        productNameSr: productGlobalData.titleSr,
                        productNameEn: productGlobalData.titleEn,
                        productNameHu: productGlobalData.titleHu,
                        productSlugSr: slugify(productGlobalData.titleSr),
                        productSlugEn: slugify(productGlobalData.titleEn),
                        productSlugHu: slugify(productGlobalData.titleHu),
                        productVisible: productGlobalData.visible,
                        productFeatured: productGlobalData.isFeatured,
                        // productEligible: productGlobalData.eligible,
                        productCategory: productGlobalData.category?._id,
                        productContentSr: productGlobalData.contentSr,
                        productContentEn: productGlobalData.contentEn,
                        productContentHu: productGlobalData.contentHu,
                        productGallery: productGlobalData.gallery,
                        productRegularPrice: productGlobalData.RegularPrice?.toFixed(2),
                        productRegularPriceVariant: '',
                        productSalePrice: productGlobalData.SalePrice?.toFixed(2),
                        productVariations: [],
                        productSku: productGlobalData.SKU,
                        productTags: productGlobalData.tags ?? [],
                        productAttributesUnit: attributes,
                        productType: 'simple',
                        productVariation: 'none',
                        productManage: productGlobalData.quantity ? true : false,
                        productStockQuantity: productGlobalData.quantity ?? '',
                        productUpcCode: productGlobalData.upccode,
                        productWeight: productGlobalData.weight
                      });
                      const imageIds = productGlobalData.gallery.map((item) => item._id);

                      setSelectImagesId(imageIds);
                      setSelected(imageIds);
                      setReviewImages(productGlobalData.gallery);
                      setFeaturedImage(productGlobalData.featuredImage);
                      setSelectedAttribute(attributes);
                      setSelectedAttributeUnit(attributes);
                      handleOnSave(attributes);
                    } else if (productGlobalData.productType === 'variable_product') {
                      setProductData(productGlobalData);

                      const productVariations = variantData.map((variant) => {
                        return {
                          RegularPrice: variant.RegularPrice,
                          SalePrice: variant.SalePrice,
                          SKU: variant.SKU,
                          weight: variant.weight,
                          Stock: variant.Stock,
                          Image: variant.Image?._id ? variant.Image._id : null,
                          Units: variant.Units.map((unit) => {
                            return unit._id;
                          })
                        };
                      });

                      const variableFormFields = (results) =>
                        variantData.map((variant) => {
                          const data = variant.Units.map((unit, idx) => {
                            const attrName = results[idx].attribute.name;
                            return {
                              [unit.value]: unit._id,
                              [attrName]: unit._id
                            };
                          });

                          return {
                            _id: generateId(),
                            variantImage: variant.Image,
                            variantSku: variant.SKU,
                            variantWeight: variant.weight,
                            variantRegularPrice: variant.RegularPrice,
                            variantSalePrice: variant.SalePrice,
                            variantQuantity: variant.Stock,
                            variantType: 'generated',
                            variant_id: variant._id,
                            units: data
                          };
                        });

                      asyncMap(variantData, getAttributeFromUnits)
                        .then((results) => {
                          setFormFields(variableFormFields(results));
                        })
                        .catch((error) => {
                          console.error('Error:', error);
                        });

                      setForm({
                        productNameSr: productGlobalData.titleSr,
                        productNameEn: productGlobalData.titleEn,
                        productNameHu: productGlobalData.titleHu,
                        productSlugEn: slugify(productGlobalData.titleEn),
                        productSlugSr: slugify(productGlobalData.titleSr),
                        productSlugHu: slugify(productGlobalData.titleHu),
                        productVisible: productGlobalData.visible,
                        productFeatured: productGlobalData.isFeatured,
                        // productEligible: productGlobalData.eligible,
                        productCategory: productGlobalData.category?._id,
                        productContentEn: productGlobalData.contentEn,
                        productContentSr: productGlobalData.contentSr,
                        productContentHu: productGlobalData.contentHu,
                        productGallery: productGlobalData.gallery,
                        productRegularPrice: '',
                        productRegularPriceVariant: '',
                        productVariantWeight: '',
                        productSalePrice: '',
                        productVariations: productVariations,
                        productSku: productGlobalData.SKU,
                        productTags: productGlobalData.tags ?? [],
                        productAttributesUnit: attributes,
                        productType: 'variables',
                        productVariation: 'custom',
                        productManage: productGlobalData.quantity ? true : false,
                        productStockQuantity: productGlobalData.quantity ?? '',
                        productUpcCode: productGlobalData.upccode
                      });
                      setReviewImages([...productGlobalData.gallery]);
                      const imageIds = productGlobalData.gallery.map((item) => item._id);
                      setSelected(imageIds);
                      setSelectImagesId([...imageIds]);
                      setFeaturedImage(productGlobalData.featureImage);
                      setSelectedAttribute(attributes);
                      setSelectedAttributeUnit(attributes);
                      setReviewImages([...productGlobalData.gallery]);
                      handleOnSave(attributes);
                    }
                  }
                })
                .catch((err) => console.error(err))
                .finally(
                  setTimeout(() => {
                    setProductAttributesLoading(false);
                  }, 700)
                );
            } catch (err) {}

            // TODO: Handle feature image
          }
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setProductLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setProductLoading(false);
    }
  }, [user, setProductLoading, setProductData, productId]);

  useEffect(() => {
    getProduct();
  }, [getProduct, setProductRefetch]);

  const values = useMemo(() => {
    return {
      form,
      setForm,
      onChange,
      onCancel,
      onSubmit,

      formError,
      setFormError,

      tabs,
      setTabs,

      onChangeUnits,

      selectedAttribute,
      setSelectedAttribute,
      toggleRefetchSelectAttr,
      setToggleRefetchSelectAttr,

      selectedAttributeUnit,
      setSelectedAttributeUnit,

      productCategoriesData,
      productCategoriesLoading,
      productCategoriesRefetch,
      setProductCategoriesRefetch,

      productAttributesData,
      productAttributesLoading,
      productAttributesRefetch,
      setProductAttributesRefetch,

      handleOnSave,
      handleOnReset,

      combineVariants,

      loadingAttributeUnit,

      toggleGallery,
      setToggleGallery,
      gallerySelect,
      setGallerySelect,
      tabsGallery,
      setTabsGallery,
      refetchGallery,
      setRefetchGallery,

      select,
      setSelect,
      onChangeItem,

      selected,
      setSelected,
      onChangeItems,

      handleOnSelect,
      handleOnSelected,

      selectImage,
      setSelectImage,
      setSelectImagesId,

      toggleGalleryId,
      setToggleGalleryId,

      reviewImages,
      selectImagesId,
      onRemoveSelected,
      getImagesByIds,
      formFields,
      setFormFields,
      generateFormFields,
      setReviewImages,
      setFeaturedImage,
      // saveCustomVariations,
      variationForm,
      setVariationForm,
      productData,
      productLoading,
      productRefetch,
      // productEligible,
      onUpdate,
      selectedUnits,
      // setSelectedUnits,
      elements,
      setElements,
      productEdit,
      setProductEdit,
      asyncMap,
      getAttributeFromUnits
    };
  }, [form, setForm, formError, setFormError, onChange, onCancel, onSubmit, tabs, setTabs, productCategoriesData, productCategoriesLoading, productCategoriesRefetch, setProductCategoriesRefetch, productAttributesData, productAttributesLoading, productAttributesRefetch, setProductAttributesRefetch, selectedAttribute, setSelectedAttribute, toggleRefetchSelectAttr, setToggleRefetchSelectAttr, selectedAttributeUnit, setSelectedAttributeUnit, onChangeUnits, handleOnSave, handleOnReset, combineVariants, loadingAttributeUnit, toggleGallery, setToggleGallery, gallerySelect, setGallerySelect, tabsGallery, setTabsGallery, refetchGallery, setRefetchGallery, select, setSelect, onChangeItem, selected, setSelected, onChangeItems, handleOnSelect, handleOnSelected, selectImage, setSelectImage, selectImagesId, setSelectImagesId, toggleGalleryId, setToggleGalleryId, setToggleGalleryId, reviewImages, selectImagesId, onRemoveSelected, getImagesByIds, formFields, setFormFields, generateFormFields, setReviewImages, setFeaturedImage, productData, productLoading, productRefetch, onUpdate, selectedUnits, elements, setElements, productEdit, setProductEdit, asyncMap, getAttributeFromUnits]);

  return <ProductContext.Provider value={values}>{children}</ProductContext.Provider>;
};

const useProducts = () => {
  return useContext(ProductContext);
};

export { ProductProvider, useProducts };
