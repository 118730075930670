const LanguageSelector = ({ onSelectLanguage }) => {
    return (
      <div>
        <button className="btn btn-primary" style={{ marginRight: '5px', padding: '0 10px 0 10px'  }} onClick={() => onSelectLanguage('sr')}>Serbian</button>
        <button className="btn btn-primary" style={{ marginRight: '5px', padding: '0 10px 0 10px' }} onClick={() => onSelectLanguage('en')}>English</button>
        <button className="btn btn-primary" style={{ marginRight: '5px', padding: '0 10px 0 10px'  }} onClick={() => onSelectLanguage('hu')}>Hungarian</button>
        {/* Add buttons for other languages */}
      </div>
    );
};

export default LanguageSelector;