import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import React, { useState, useEffect } from 'react';
import TermsForm from './TermsOfServiceForm';

const TermsOfService = () => {
  const { user } = useAuth();
  const { token } = user;

  const [data, setData] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const lastIndex = window.location.pathname.lastIndexOf('/');
  const about = window.location.pathname.slice(lastIndex + 1);

  useEffect(() => {
    fetchData();
  }, [about]);

  const fetchData = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/single-pages?type=terms`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(res.data.item);
      setIsDataFetched(true);
    } catch (error) {
      console.error('Error fetching the data:', error);
    }
  };

  return <div className="title-container">{data && isDataFetched && <TermsForm isNew={data && data.length > 0 ? false : true} data={data} />}</div>;
};

export default TermsOfService;
