import { Input, Form, Button, notification, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useRef } from 'react';

const UpdateBlogForm = ({ data }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const initialValues = data;

  const [desc, setDescription] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fileInputRef = useRef(null);
  const [blogImageId, setBlogImageId] = useState(undefined);
  const [blogImage, setBlogImage] = useState(undefined);

  const getTextValue = (value) => {
    if (value !== desc) {
      setDescription(value);
    }
  };

  const handleUploadImage = async (event) => {
    const formData = new FormData();

    formData.append('file', fileInputRef.current.files[0]);

    try {
      const response = await Axios.post(`${SERVER_URL}/upload-blog-image`, formData, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

      setBlogImageId(response.data.id);
      setBlogImage(response.data.image);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const onFinish = async (values) => {
    values.desc = desc;
    if (blogImageId) {
      values.image = blogImageId;
    }

    await Axios.put(`${SERVER_URL}/blog/${data._id}`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'Blog is updated.' });

    navigate('/admin/grocery/blogs');
  };

  return (
    <div className="title-container">
      <Form initialValues={initialValues} onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p>UPDATE BLOG</p>
        </div>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <Link to={'/admin/grocery/blogs'} style={{ color: 'black' }}>
            ALL BLOGS
          </Link>
        </div>

        <Form.Item name="title" label="Title">
          <Input type="text" placeholder="title" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="url" label="Url">
          <Input type="text" placeholder="url" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaTitle" label="Meta Title">
          <Input type="text" placeholder="metaTitle" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaDesc" label="Meta Description">
          <Input type="text" placeholder="metaDesc" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="image" label="Image">
          <Button
            onClick={() => {
              setIsModalVisible(true);
            }}
            type="primary"
          >
            Add image
          </Button>
        </Form.Item>
        {!blogImage && data.image && data.image.url && data.image.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${data.image.url}`} alt={data.image.originalname} />}
        {blogImage && blogImageId && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${blogImage.url}`} alt={blogImage.originalname} />}

        <div className="tiny-blog-page">
          <Form.Item name="desc" label="Description">
            <Editor
              apiKey={apiKey}
              onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
              init={{
                height: 600,
                menubar: true,
                plugins: 'table',
              }}
            />
          </Form.Item>
        </div>
        <Modal title="Add picture" open={isModalVisible} footer={[]} onCancel={handleCancel}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <input type="file" ref={fileInputRef} />
            <button onClick={handleUploadImage}>Upload</button>
            <Button>Open gallery</Button>
          </div>
        </Modal>
        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
          UPDATE BLOG
        </Button>
      </Form>
    </div>
  );
};

export default UpdateBlogForm;
