import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import React, { useState, useEffect } from 'react';
import HeroBanersForm from './HeroBanersForm';

const HeroBaners = () => {
  const { user } = useAuth();
  const { token } = user;

  const [data, setData] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/hero-baners`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(res.data.item);
      setIsDataFetched(true);
    } catch (error) {
      console.error('Error fetching the data:', error);
    }
  };

  return <div className="title-container">{isDataFetched && <HeroBanersForm isNew={data ? false : true} data={data} />}</div>;
};

export default HeroBaners;
