import React, { useEffect, useState } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { ProductProvider, useProducts } from '../../../../context/products';
import { ProductBreadcrumb } from './product-breadcrumb/ProductBreadcrumb';
import Loading from '../../../../components/loading/Loading';
import { ProductInfo } from '../create-product/product-info/ProductInfo';
import { ProductGallery } from '../create-product/product-gallery/ProductGallery';
import { ProductType } from '../create-product/product-type/ProductType';
import { ProductOptions } from '../create-product/product-options/ProductOptions';
import { ProductTags } from '../create-product/product-tags/ProductTags';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import { PERMISSIONS } from '../../../../utils/permissions';
import ProductUpcCode from '../create-product/product-upccode/ProductUpcCode';

import LanguageSelector from '../../../../helpers/LanguageSelector';
import { useTranslation } from 'react-i18next';

const UpdateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useProducts();

  useEffect(() => {
    setProductEdit(true);
  }, []);

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const UpdateProductSubmit = (props) => {
  const { form, onCancel, onUpdate, productCategoriesLoading } = useProducts();

  const { t } = useTranslation();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
            <span className="text">{t('Cancel')}</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}

        {!productCategoriesLoading ? (
          <button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`)} className="btn btn-primary" type="button">
            <span className="text">{t('Update Product')}</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: '176px', height: '50px' }} />
        )}
      </div>
    </footer>
  );
};

const UpdateProduct = () => {

  const [language, setLanguage] = useState('sr');

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  useEffect(() => {
    handleLanguageChange(language);
  }, []);
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  return (
    <ProductProvider>
      <ProductBreadcrumb />
      <Section className="section__product section__product--wrapper section__update-product">
        <UpdateProductLoader>
          <Row>
            <Col>
              <Wrapper>
                <LanguageSelector onSelectLanguage={handleLanguageChange} />
                <ProductInfo className="product__card--info" language={language} />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <ProductGallery className="product__card--gallery" language={language} />
                {/* <ProductType className="product__card--type" /> */}
                <ProductOptions className="product__card--options" language={language} />
                {/* <ProductUpcCode className="product__card--upccode" /> */}
                <ProductTags className="product__card--tags" language={language} />
              </Wrapper>
            </Col>
          </Row>
        </UpdateProductLoader>
        <UpdateProductSubmit language={language} />
      </Section>
    </ProductProvider>
  );
};

export default UpdateProduct;
