import React, { Fragment } from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Form, Row, Col } from 'antd';
import Loading from '../../../../components/loading/Loading';

export const UpdateRole = ({ handleSelectCheckbox, updateProps, loadingGrocery, updateFormRef, handleSubmit, updateRoleGroceryData, handleOnChange, handleValidation, toggleSwitchUpdate, handleToggleSelectAll, handleSelectCheckboxRows, setToggleUpdate, editBtnLoader }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Role">
      {!loadingGrocery ? (
        <form name="update" ref={updateFormRef} className={`ant-form ant-form-horizontal`} onSubmit={(e) => handleSubmit(e)}>
          <Row className="mb-4">
            <Col span={24}>
              <div className="ant-form-item-control-input-content" style={{ position: 'relative' }}>
                <label className="label mb-1 d-block" htmlFor="nameUpdateRole">
                  <b className="required">Name:</b>
                </label>
                <input id="nameUpdateRole" data-cy="modal-edit-role-name" type="text" style={{ width: '100%' }} defaultValue={updateRoleGroceryData.roleName} placeholder="Name:" onChange={(e) => handleOnChange(e)} onBlur={(e) => handleValidation(e)} name="name_update_role" />
                <p data-cy="modal-edit-role-name-error" className={`error__onblur name_update_role-error__required`}>
                  This field is required!
                </p>
              </div>
            </Col>
          </Row>

          {!loadingGrocery && (
            <>
              {!!updateRoleGroceryData.permissions && (
                <Fragment>
                  <hr className="hr" />

                  <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                    <Col span={12}>
                      <p className="mb-0">Permissions</p>
                    </Col>
                    <Col span={12}>
                      <div className="select-all-checkbox">
                        <input data-cy="checkbox-select-all-update-grocery" type="checkbox" id="all_update_grocery" name="all_update_grocery" onClick={(e) => handleToggleSelectAll(e)} />
                        <label data-cy="label-select-all-update-grocery" htmlFor="all_update_grocery">
                          Select all
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <hr className="hr mb-4" />

                  {updateRoleGroceryData &&
                    Object.entries(updateRoleGroceryData?.permissions).map((item, idx) => {
                      const label = Object.values(item)[0];
                      const items = Object.values(item)[1];

                      return (
                        <Form.Item className="checkbox-rows" key={idx}>
                          <div className="row-checkbox" key={label}>
                            <input data-cy="checkbox-select-rows-update-grocery" type="checkbox" id={`update_grocery_${label}`} value={`update_grocery_${label}`} name={`update_grocery_${label}`} onChange={(e) => handleSelectCheckboxRows(e)(items)} />
                            <label data-cy="label-select-rows-update-grocery" htmlFor={`update_grocery_${label}`} style={{ textTransform: 'capitalize' }}>
                              {label}
                            </label>
                          </div>

                          <Row className="checkbox-cols" data-cy="checkbox-grocery-edit-permissions">
                            {items?.map((check) => {
                              return (
                                <div className="checkbox-permission" key={check._id}>
                                  <input data-cy="checkbox-update-grocery-permissions" type="checkbox" id={`U_G_${check._id}`} value={check._id} name={`update_grocery_roles_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} defaultChecked={check.checked} />
                                  <label data-cy="label-update-grocery-permissions" htmlFor={`U_G_${check._id}`}>
                                    {check.label}
                                  </label>
                                </div>
                              );
                            })}
                          </Row>
                        </Form.Item>
                      );
                    })}
                </Fragment>
              )}
            </>
          )}

          <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
            <Row gutter={16}>
              <Col span={12}>
                <button data-cy="modal-edit-cancel-role-btn" type="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Cancel</span>
                </button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <button data-cy="modal-edit-submit-role-btn" type="submit" className="btn btn-primary btn-block">
                    <span>Edit Role</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline btn-block">
                    <span>Editing...</span>
                  </button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
