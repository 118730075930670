import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Form, Row, Col } from 'antd';

export const CreateRole = ({ createRole, createProps, handleSubmit, createFormRef, handleOnChange, handleValidation, handleToggleSelectAll, grocery, rowCheckedGrocery, handleSelectCheckboxRows, handleSelectCheckbox, isCheckboxGrocery, createBtnLoader, setToggleCreate }) => {
         return (
           <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Role">
             <form ref={createFormRef} className={`ant-form ant-form-horizontal`} name="create" onSubmit={(e) => handleSubmit(e)}>
               <Row className="mb-4">
                 <Col span={24}>
                   <div className="ant-form-item-control-input-content" style={{ position: 'relative' }}>
                     <label className="label mb-1 d-block" htmlFor="nameCreateRole">
                       <b className="required">Name:</b>
                     </label>

                     <input id="nameCreateRole" data-cy="modal-create-role-name" type="text" style={{ width: '100%' }} value={createRole.name || ''} placeholder="Name:" onChange={(e) => handleOnChange(e)} onBlur={(e) => handleValidation(e)} name="name_role" />
                     <p data-cy="modal-create-role-name-error" className={`error__onblur name_role-error__required`}>
                       This field is required!
                     </p>
                   </div>
                 </Col>
               </Row>

               {/* GROCERY */}
               <hr className="hr" />

               <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                 <Col span={12}>
                   <p className="mb-0">Permissions</p>
                 </Col>
                 <Col span={12}>
                   <div className="select-all-checkbox">
                     <input data-cy="checkbox-select-all-create-grocery" type="checkbox" id="all_grocery" name="all_grocery" onClick={(e) => handleToggleSelectAll(e)} />
                     <label data-cy="label-select-all-create-grocery" htmlFor="all_grocery">
                       Select all
                     </label>
                   </div>
                 </Col>
               </Row>

               <hr className="hr mb-4" />

               {grocery.length > 0 &&
                 grocery.map((item, idx) => {
                   return (
                     <Form.Item key={idx} className="checkbox-rows">
                       <div className="row-checkbox" key={item._id}>
                         <input data-cy="checkbox-select-rows-create-grocery" type="checkbox" id={`grocery_${item._id}`} value={`grocery_${item._id}`} name={`grocery_${item._id}`} onChange={(e) => handleSelectCheckboxRows(e)(item.permissions)} checked={rowCheckedGrocery.includes(`grocery_${item._id}`)} />
                         <label data-cy="label-select-rows-create-grocery" htmlFor={`grocery_${item._id}`} style={{ textTransform: 'capitalize' }}>
                           {item._id}
                         </label>
                       </div>
                       <Row className="checkbox-cols" data-cy="checkbox-grocery-create-permissions">
                         {item?.permissions.map((check) => {
                           return (
                             <div className="checkbox-permission" key={check._id}>
                               <input type="checkbox" id={check._id} value={check._id} name={`grocery_checkbox_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} checked={isCheckboxGrocery.includes(check._id)} />
                               <label htmlFor={check._id}>{check.label}</label>
                             </div>
                           );
                         })}
                       </Row>
                     </Form.Item>
                   );
                 })}

               <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
                 <Row gutter={16}>
                   <Col span={12}>
                     <button data-cy="modal-create-cancel-role-btn" type="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                       <span>Cancel</span>
                     </button>
                   </Col>
                   <Col span={12}>
                     {!createBtnLoader ? (
                       <button data-cy="modal-create-submit-role-btn" type="submit" className="btn btn-primary btn-block">
                         <span>Add Role</span>
                       </button>
                     ) : (
                       <button type="button" className="btn btn-primary-outline btn-block">
                         <span>Creating...</span>
                       </button>
                     )}
                   </Col>
                 </Row>
               </Form.Item>
             </form>
           </SidebarModal>
         );
       };
