import React from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';

import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { data } = props;
  let subtotal = 0;

  const { t } = useTranslation();

  const isProductImage = (item) => {
    const isCheckFeaturedImage = item && item.hasOwnProperty('featureImage');

    if (isCheckFeaturedImage) {
      const featureName = item && item.featureImage.originalname;
      const featureImage = item && item.featureImage && item.featureImage.url;

      return <img src={featureImage} alt={featureName} />;
    }
    return <div>1</div>;
  };

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

  // console.log('order details order', data);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Stavke</th>
            <th>Količina</th>
            <th>Adresa dostave</th>
            <th>Način isporuke</th>
            <th>Način plaćanja</th>
            <th>Cena</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.order.length > 0 ? (
            data?.order.map((item, idx) => {
              subtotal = subtotal + parseFloat(item.totalPrice);
              // console.log(data);
              const address = data.userData[0].Ulica + ' ' + data.userData[0].BrojKuce  + ', ' + data.userData[0].Grad;
              // console.log('addressssss', item);
              return (
                <tr key={item.id}>
                  <td>
                    <div className="product-item">
                      {/* {isProductImage(item)} */}
                      <span>{item.titleSr}</span>
                    </div>
                  </td>
                  <td>{item.quantity}</td>
                  <td>{address}</td>
                  <td>{t(data.shipmentType === 'Package' ? 'Package' : 'PostExpress')}</td>
                  <td>{data.paymentType === 'Payment On Delivery' ? 'Plaćanje pouzećem' : 'Plaćanje karticom'}</td>
                  <td>{parseFloat(item.price).toFixed(2)} RSD</td>
                </tr>         
              );
            })
          ) : (
            <tr>
              <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
          {data && data.order.length > 0 ? (
            <tr className="subtotal-row">
              <td style={{ textAlign: 'right', paddingRight: '5px' }} colSpan={7}>
                Ukupno: {parseFloat(data.totalPrice).toFixed(2)} RSD
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <div>
        <span style={{marginLeft:"20px", fontSize:"24px"}}><strong>DODATNE INFORMACIJE:</strong></span>
        <ul style={{marginLeft:"30px"}}>
          <li>
            <strong>Ime kupca:</strong> {data?.userData[0].Ime}
          </li>
          <li>
            <strong>Prezime kupca:</strong> {data?.userData[0].Prezime}
          </li>
          <li>
            <strong>Email:</strong> {data?.userData[0].Email}
          </li>
          <li>
            <strong>Kontakt:</strong> {data?.userData[0]?.PozivniBroj + ' ' + data?.userData[0]?.BrojTelefona}
          </li>
        </ul>
      </div>
    </>
  );
};

export default Table;
