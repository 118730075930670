import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

const QuestionContext = createContext(null);

const QuestionProvider = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // Form
  const [form, setForm] = useState({
    personFullName: '',
    email: '',
    description: '',
    datePosted: '',
    answer: ''
  });

  const [formError, setFormError] = useState({
    personFullName: '',
    email: '',
    description: '',
    datePosted: '',
    answer: ''
  });

  const [formFields, setFormFields] = useState([]);

  // On Change
  const onChange = (event) => {
    const { name, value, checked } = event.target;

    setForm((prevState) => {
        return { ...prevState, [name]: value };
    });
  };

  // On Valid
  const onValid = useCallback(
    (form) => {
      let checkIsValid = false;

      // validate input fields for simple product
      let checkPersonFullName = false;
      let checkEmail = false;
      let checkDescription = false;

      const pattern = /^\$?\d+(\.\d{2})?$/;
      const pattern2 = /^\$?\d+(\.\d{1})?$/;

      if (form.personFullName.length > 0) {
        checkPersonFullName = true;
        setFormError((prevState) => {
          return { ...prevState, personFullName: false };
        });
      } else {
        checkPersonFullName = false;
        setFormError((prevState) => {
          return { ...prevState, personFullName: true };
        });
      }
      if (form.email.length > 0) {
        checkEmail = true;
        setFormError((prevState) => {
          return { ...prevState, email: false };
        });
      } else {
        checkEmail = false;
        setFormError((prevState) => {
          return { ...prevState, email: true };
        });
      }
      if (form.description.length > 0) {
        checkDescription = true;
        setFormError((prevState) => {
          return { ...prevState, description: false };
        });
      } else {
        checkDescription = false;
        setFormError((prevState) => {
          return { ...prevState, description: true };
        });
      }

      return checkIsValid;
    },
    [formError, form]
  );

  // On Cancel
  const onCancel = () => {
    window.location.reload();
  };

  // On Submit
  const onUpdate = useCallback(
    async (path) => {

      let payload = {
        personFullName: form.personFullName,
        email: form.email,
        description: form.description,
        datePosted: form.datePosted,
        answer: form.answer
      };

      const { token } = user;
      try {
        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.updateQuestion}/${questionId}`,
            { ...payload },
            {
              withCredentials: false,
              headers: {
                Authorization: `Bearer ${token}`,
                department: PERMISSIONS.grocery
              }
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // redirect
              navigate(`/${path}`);

              // notification
              notification.success({
                message: 'Successfully updated a question.',
                placement: 'bottomLeft'
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {});
      } catch (err) {
        console.error(err);
      }
    },
    [form, formFields]
  );

  const generateFormFields = () => {
    if (combineVariants && combineVariants.length > 0) {
      if (!formFields || formFields.length === 0) {
        const newFormFields = combineVariants.map((item) => {
          return generateNewFormBase();
        });
        newFormFields.map((item, i) => {
          item.units = combineVariants[i].options.map((opt) => {
            return {
              [opt.units.value]: opt.units._id
            };
          });
        });

        setFormFields(newFormFields);
      } else if (formFields && formFields.length > 0) {
        const allUnitCombinations = combineVariants.map((item) => {
          return item.options.map((opt) => {
            return { [opt.units.value]: opt.units._id };
          });
        });

        const filteredFormFields = formFields.filter((field) => {
          if (field.variantType === 'generated') {
            return allUnitCombinations.some((combination) => {
              return combination.every((unit) => {
                const [key] = Object.keys(unit);
                return field.units.some((fieldUnit) => {
                  return fieldUnit.hasOwnProperty(key) && fieldUnit[key] === unit[key];
                });
              });
            });
          } else {
            return field;
          }
        });

        const missingCombinations = allUnitCombinations.filter((combination) => {
          return !filteredFormFields.some((field) => {
            if (field.variantType === 'generated') {
              return field.units.every((unit) => {
                const key = Object.keys(unit)[0];
                return combination.some((option) => {
                  const fieldKey = Object.keys(option)[0];
                  return fieldKey === key && option[fieldKey] === unit[key];
                });
              });
            }
          });
        });

        const newFormFields = missingCombinations.map((units) => {
          return {
            ...generateNewFormBase(),
            units
          };
        });

        setFormFields([...filteredFormFields, ...newFormFields]);
      } else {
        setFormFields(formFields);
      }
    } else {
      setFormFields([]);
    }
  };

  const handleOnSave = (data) => {
    setLoadingAttributeUnit(true);
    setTimeout(() => setLoadingAttributeUnit(false), 700);
    setSelectedAttributeUnit(data);
    setForm((prevState) => {
      return { ...prevState, productAttributesUnit: data };
    });
  };

  const handleOnReset = () => {
    setSelectedAttribute([]);
    setSelectedAttributeUnit([]);
  };

  const [questionData, setQuestionData] = useState({});
  const [questionLoading, setQuestionLoading] = useState(true);
  const [questionEdit, setQuestionEdit] = useState(false);

  const { id: questionId } = useParams();

  const getQuestion = useCallback(async () => {
    const { token } = user;

    try {
      setQuestionLoading(true);
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.getSingleQuestion}/${questionId}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async (res) => {

          if (res.status === 200) {
            // console.log('res data single question', res.data.item);
            setQuestionData(res.data.item)

            // TODO: Handle feature image

            setForm({
              personFullName: res.data.item.personFullName,
              description: res.data.item.description,
              email: res.data.item.email,
              datePosted: res.data.item.datePosted,
              answer: res.data.item.answer
            });
          }
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setQuestionLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setQuestionLoading(false);
    }
  }, [user, setQuestionLoading, setQuestionData, questionId]);

  useEffect(() => {
    getQuestion();
  }, [getQuestion]);

  const values = useMemo(() => {
    return {
      form,
      setForm,
      onChange,
      onCancel,

      formError,
      setFormError,

      handleOnSave,
      handleOnReset,

      formFields,
      setFormFields,
      generateFormFields,
      questionData,
      questionLoading,
      onUpdate,
      questionEdit,
      setQuestionEdit,
    };
  }, [form, setForm, formError, setFormError, onChange, onCancel, onValid, handleOnSave, handleOnReset, questionEdit, setQuestionEdit]);

  return <QuestionContext.Provider value={values}>{children}</QuestionContext.Provider>;
};

const useQuestions = () => {
  return useContext(QuestionContext);
};

export { QuestionProvider, useQuestions };