import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useProducts } from '../../../../../../context/products';

const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';
export const Description = (props) => {
  const { form, setForm, productEdit } = useProducts();
  const { label, className, language } = props;

  const [descriptionSr, setDescriptionSr] = useState(form?.productContentSr);
  const [descriptionEn, setDescriptionEn] = useState(form?.productContentEn);
  const [descriptionHu, setDescriptionHu] = useState(form?.productContentHu);
  const getTextValue = (value) => {
    if (language === 'en' && value !== descriptionEn) {
      setDescriptionEn(value);
    } else if (language === 'sr' && value !== descriptionSr) {
      setDescriptionSr(value);
    } else if (language === 'hu' && value !== descriptionHu) {
      setDescriptionHu(value);
    }
  };

  useEffect(() => {
    if ((descriptionEn && descriptionEn.length > 0) || (descriptionSr && descriptionSr.length > 0) || (descriptionHu && descriptionHu.length > 0)) {
      setForm((prevState) => {
        if (language === 'en') {
          return { ...prevState, productContentEn: descriptionEn };
        } else if (language === 'sr') {
          return { ...prevState, productContentSr: descriptionSr };
        } else {
          return { ...prevState, productContentHu: descriptionHu };
        }
        
      });
    }
  }, [descriptionEn, descriptionSr, descriptionHu, setForm]);

  useEffect(() => {
    if (productEdit && (form.productContentEn !== '' || form.productContentSr !== '' || form.productContentHu !== '')) {
      if (language === 'en') {
        setDescriptionEn(form.productContentEn);
      } else if (language === 'sr') {
        setDescriptionSr(form.productContentSr);
      } else {
        setDescriptionHu(form.productContentHu);
      }

    }
  }, []);

  return (
    <div className="custom-select">
      <span className="label">
        <b>{label}:</b>
      </span>

      {
        language === 'en' ? (
          <>
                <div className="select select-result">
        <Editor
          apiKey={apiKey}
          onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
          init={{
            height: 600,
            menubar: true,
            plugins: 'table'
          }}
          value={descriptionEn}
        />
      </div>
          </>
        ) : language === 'sr' ? (
          <>
                <div className="select select-result">
        <Editor
          apiKey={apiKey}
          onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
          init={{
            height: 600,
            menubar: true,
            plugins: 'table'
          }}
          value={descriptionSr}
        />
      </div>
          </>
        ) : (
          <>
          <div className="select select-result">
  <Editor
    apiKey={apiKey}
    onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
    init={{
      height: 600,
      menubar: true,
      plugins: 'table'
    }}
    value={descriptionHu}
  />
</div>
    </>
        )
      }

    </div>
  );
};
