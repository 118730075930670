import { Input, Form, Button, notification, Modal, Upload } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

const CreateBlog = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const [desc, setDescription] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blogImageId, setBlogImageId] = useState(undefined);
  const [image, setBlogImage] = useState({});
  const fileInputRef = useRef(null);

  const { t } = useTranslation();

  const getTextValue = (value) => {
    if (value !== desc) {
      setDescription(value);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const onFinish = async (values) => {
    values.desc = desc;
    values.image = blogImageId;
    await Axios.post(`${SERVER_URL}/blogs`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'Blog is created.' });

    navigate('/admin/grocery/blogs');
  };

  const handleUploadImage = async (event) => {
    const formData = new FormData();

    formData.append('file', fileInputRef.current.files[0]);

    try {
      const response = await Axios.post(`${SERVER_URL}/upload-blog-image`, formData, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

      setBlogImageId(response.data.id);
      setBlogImage(response.data.image);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const customStyle = {
    color: 'white',
  };

  return (
    <div className="title-container">
      <Form onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p style={customStyle} >DODAJ NOVI BLOG</p>
        </div>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <Link to={'/admin/grocery/blogs'} style={{ color: 'white' }}>
            Svi blogovi
          </Link>
        </div>
        <Form.Item name="title" label="Naslov">
          <Input type="text" placeholder="naslov" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="url" label="Link">
          <Input type="text" placeholder="link" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="metaTitle" label="Meta Naslov">
          <Input type="text" placeholder="meta naslov" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="metaDesc" label="Meta Opis">
          <Input type="text" placeholder="meta opis" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="image" label="Slika">
          <Button
            onClick={() => {
              setIsModalVisible(true);
            }}
            type="primary"
          >
            Dodaj sliku
          </Button>
        </Form.Item>
        {image && image?.url && image?.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${image?.url}`} alt={image?.originalname} />}
        <div className="tiny-blog-page">
          <Editor
            apiKey={apiKey}
            onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
            init={{
              height: 600,
              menubar: true,
              plugins: 'table',
            }}
          />
        </div>

        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
          Dodaj blog
        </Button>

        <Modal title="Add picture" open={isModalVisible} footer={[]} onCancel={handleCancel}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <input type="file" ref={fileInputRef} />
            <button onClick={handleUploadImage}>Postavi</button>
            <Button>Otvori galeriju</Button>
          </div>
        </Modal>
      </Form>
    </div>
  );
};

export default CreateBlog;
