import { notification } from 'antd';
import { useState } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { useTranslation } from 'react-i18next';

export const ProductTags = (props) => {
  const { form, setForm } = useProducts();
  const { className, language } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState({ tags: '' });

  const onValid = (data) => {
    let checkValid = false;

    let checkTagsEmpty = false;
    let checkTagsLength = false;
    let checkTagsUnique = false;

    if (data.tags === '') {
      checkTagsEmpty = false;
    } else {
      checkTagsEmpty = true;
    }

    if (data.tags.length < 2) {
      notification.warn({
        message: 'Min length character 2 or more',
        placement: 'bottomRight'
      });
      checkTagsLength = false;
    } else {
      checkTagsLength = true;
    }

    if (Object.values(form.productTags).some((key) => key === value.tags)) {
      notification.error({
        message: 'This value same at created!',
        placement: 'bottomRight'
      });
      checkTagsUnique = false;
    } else {
      checkTagsUnique = true;
    }

    if (!checkTagsEmpty && !checkTagsLength && !checkTagsUnique) {
      checkValid = false;
    }

    if (checkTagsEmpty && checkTagsLength && checkTagsUnique) {
      checkValid = true;
    }

    return checkValid;
  };

  const onChange = (event) => {
    const { value } = event.target;

    setValue({ tags: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const isValid = onValid(value);

    if (isValid) {
      setForm((prevState) => {
        return {
          ...prevState,
          productTags: [...prevState.productTags, value.tags]
        };
      });

      // Reset field
      setValue({ tags: '' });
    }
  };

  const onRemove = (item) => {
    const newTags = form.productTags.filter((t) => t !== item);
    setForm((prevState) => {
      return { ...prevState, productTags: newTags };
    });
  };

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header">
        <h4>{t('productTags')}</h4>
      </header>

      <main className="card-content">
        <form name="product-tags" onSubmit={onSubmit} className="form">
          <div className="form-group">
            <span className="label">
              <b>{t('productTags')}:</b>
            </span>

            <div className="tags">
              <div className="form-group">
                <input name="tags" value={value.tags || ''} onChange={onChange} type="text" className="input" placeholder={t('productTags')} />
              </div>

              <button type="submit" className="btn btn-primary pl-2 pr-2">
                <span className="text">{t('add')}</span>
              </button>
            </div>
          </div>
        </form>

        {form.productTags && form.productTags.length > 0 && (
          <div className="result result-tags">
            {form.productTags.map((item, idx) => {
              return (
                <span onClick={() => onRemove(item)} className="item" key={idx}>
                  <span className="icon">
                    <img src="/assets/icons/times-small.svg" alt="Remove Tags" title={item} />
                  </span>
                  <span className="text">{item}</span>
                </span>
              );
            })}
          </div>
        )}
      </main>
    </Wrapper>
  );
};
