import { Wrapper } from '../../../../components/content'
import { useQuestions } from '../../../../context/questions';

export const QuestionInfo = (props) => {
  const { form, onChange, formError } = useQuestions();
  const { className } = props;

  // console.log('FORM', form);

  return (
    <Wrapper className={`product__card ${className}`}>
      <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>Question Info</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Person Name:</b>
              </label>
              <input value={form.personFullName || ''} onChange={onChange} name="personFullName" disabled placeholder="Full Name" type="text" id="personFullName" className={`input ${formError.personFullName ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.personFullName ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Email:</b>
              </label>
              <input value={form.email || ''} onChange={onChange} name="email" placeholder="Email" disabled type="text" id="email" className={`input ${formError.email ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.email ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Description:</b>
              </label>
              <input value={form.description || ''} onChange={onChange} name="description" placeholder="Question" disabled type="text" id="description" className={`input ${formError.description ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.description ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Date posted:</b>
              </label>
              <input value={form.datePosted || ''} onChange={onChange} name="datePosted" placeholder="Date posted" disabled type="text" id="datePosted" className={`input ${formError.datePosted ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.datePosted ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Answer:</b>
              </label>
              <input value={form.answer || ''} onChange={onChange} name="answer" placeholder="Answer" type="text" id="answer" className={`input ${formError.answer ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.answer ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>
          </form>
        </main>
      </div>
    </Wrapper>
  );
};
