import React, { useEffect, useRef, useState } from 'react';
import { useProducts } from '../../../../../../context/products';
import { useTranslation } from 'react-i18next';

// GENERATED OTP
const generateOtp = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

// CREATE ATTRIBUTES FROM FORM IN TOP
function AddAttributes(props) {
  const { productAttributesData, selectedAttribute } = useProducts();
  const { form, onSubmit, onChange } = props;

  const isChecked = (list) => {
    return selectedAttribute.some((item) => list.includes(item._id));
  };

  const { language } = props

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit} name="" className="form form-create-attributes">
      <div className="form-group">
        <label className="label" htmlFor="attributes">
          <b>{t('attributes')}:</b>
        </label>

        <select className="select" name="value" id="attributes" value={form.value || 'none'} onChange={onChange}>
          <option value="none">{t('selectAttributes')}</option>

          {productAttributesData.map((item) => {
            return (
              <option style={{ color: isChecked(item._id) ? 'silver' : 'black' }} key={item._id} value={item._id}>
                {t(item.name)}
              </option>
            );
          })}
        </select>
      </div>

      <button type="submit" className="btn btn-primary">
        <span className="text">{t('add')}</span>
      </button>
    </form>
  );
}

// UNIT PER ROW
function ToggleUnit(props) {
  const { data, id, rowKey, idx } = props;
  const { selectedAttribute, setSelectedAttribute, onChangeUnits, productData, productLoading, form, productEdit, productAttributesData } = useProducts();

  const elementRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState([]);
  const [elements, setElements] = useState(data.units || []);

  useEffect(() => {
    if (productEdit) {
      if (form.productAttributesUnit[idx]?.selectedUnits && form.productAttributesUnit[idx]?.selectedUnits.length > 0) {
        setSelected([...form.productAttributesUnit[idx]?.selectedUnits]);
      }
    }
  }, [productLoading, productData]);

  useEffect(() => {
    if (productEdit && selected && selected.length > 0) {
      const originalUnits = productAttributesData.map((item) => item.units);

      const newElements = originalUnits[idx].filter((item) => {
        return !selected.some((select) => select?._id === item?._id);
      });

      setElements(newElements);
    }
  }, [selected]);

  // useEffect(() => {
  //   if (productEdit) {
  //     if (
  //       form.productAttributesUnit[idx]?.selectedUnits &&
  //       form.productAttributesUnit[idx]?.selectedUnits.length > 0
  //     ) {
  //       const selectedUnitsIds = form.productAttributesUnit[
  //         idx
  //       ]?.selectedUnits.map((item) => item._id);

  //       for (let i = 0; i < selectedUnitsIds.length; i++) {
  //         onSelect({ action: "add", payload: selectedUnitsIds[i] });
  //       }
  //     }
  //   }
  // }, [productLoading, productData]);

  const onSelect = (props) => {
    const { action, payload } = props;

    switch (action) {
      case 'add':
        const newArrRemove = elements.filter((item) => item._id !== payload);
        setElements(newArrRemove);
        const newUpdateResult = elements.filter((item) => item._id === payload);
        setSelected([...selected, ...newUpdateResult]);
        // test
        onChangeUnits({
          action: 'add',
          rowId: rowKey,
          rowData: [...selected, ...newUpdateResult]
        });
        break;
      case 'remove':
        const newArrAdd = selected.filter((item) => item._id !== payload);
        setSelected(newArrAdd);
        const newRemoveResult = selected.filter((item) => item._id === payload);
        setElements([...elements, ...newRemoveResult]);
        // test
        onChangeUnits({ action: 'remove', rowId: rowKey, rowData: newArrAdd });
        break;
      case 'clear':
        const newUpdate = selectedAttribute.filter((item) => item._id !== payload);
        setSelectedAttribute(newUpdate);
        break;
      default:
        break;
    }
  };

  const onClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', onClickOutside, false);
    };
  }, [elementRef]);

  return (
    <React.Fragment>
      <div ref={elementRef} className="custom-select custom-select-remove">
        <span className="label">
          <b>{data.name}:</b>
        </span>

        <div className="select select-result" onClick={() => setToggle((t) => !t)}>
          {/* <div className="select-all">
            <div className="form-group">
              <input type="checkbox" className="checkbox" id={data._id} onClick={(event) => onSelect({ action: 'all', payload: id, event: event })} />
              <label className="label" htmlFor={data._id}>
                <b>Select All</b>
              </label>
            </div>
          </div> */}

          <div className="result">
            {selected && selected.length > 0 ? (
              selected.map((item) => {
                return (
                  <span
                    onClick={() => {
                      onSelect({ action: 'remove', payload: item._id });
                      setToggle((t) => !t);
                    }}
                    className="item"
                    key={item._id}
                  >
                    <span className="text">{item.value}</span>
                    <span className="icon">
                      <img src="/assets/icons/times-small.svg" alt="Remove Tags" title={item.value} />
                    </span>
                  </span>
                );
              })
            ) : (
              <span className="no-data">No selected</span>
            )}
          </div>

          <button type="button" className="btn">
            <span className="icon">
              <img src="/assets/icons/arrow-down-table-filters.svg" alt="Arrow down" className="img" />
            </span>
          </button>

          <button type="button" className="btn btn-remove" onClick={() => onSelect({ action: 'clear', payload: id })}>
            <span className="icon">
              <img src="/assets/icons/delete.svg" alt="Delete" className="img" />
            </span>
          </button>
        </div>

        <div className={`select select-area ${toggle ? 'isActive' : ''}`}>
          <div className="result">
            {elements && elements.length > 0 ? (
              elements.map((item) => {
                return (
                  <span
                    onClick={() => {
                      onSelect({ action: 'add', payload: item._id });
                      setToggle(true);
                    }}
                    className="item"
                    key={item._id}
                  >
                    <span className="text">{item.value}</span>
                  </span>
                );
              })
            ) : (
              <span className="no-data">No selected</span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

// RENDER LIST OF CREATE ATTRIBUTE
function AddAttributesUnits(props) {
  const { onClick } = props;
  const { selectedAttribute, setSelectedAttributeUnit, handleOnReset, handleOnSave, loadingAttributeUnit, selectedAttributeUnit } = useProducts();

  useEffect(() => {
    if (selectedAttribute && selectedAttribute.length < 1) {
      setSelectedAttributeUnit([]);
    }
  }, [selectedAttribute]);

  return (
    <React.Fragment>
      {selectedAttribute.map((item, idx) => {
        return <ToggleUnit key={item._id} data={item} rowKey={item.key} id={item._id} onClick={onClick} idx={idx} />;
      })}

      {selectedAttribute && selectedAttribute.length > 0 && (
        <div className="actions">
          <button type="button" className="btn btn-primary-outline" onClick={() => handleOnReset()}>
            <span className="text">Reset</span>
          </button>

          <button type="button" className="btn btn-primary btn-save" onClick={() => handleOnSave(selectedAttribute)}>
            {loadingAttributeUnit && (
              <span className="icon">
                <LoadingSpinner />
              </span>
            )}
            <span className="text">Save Changes</span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const loadingContainer = {
  width: '100%',
  textAlign: 'center',
  display: 'grid',
  alignItems: 'center',
  columnGap: '15px',
  gridTemplateColumns: '24px'
};

const loadingAnimation = {
  width: '20px',
  height: '20px',
  border: '2px solid #e7e7e7',
  borderTop: '2px solid #4761e6',
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
  margin: 'auto',
  display: 'block'
};

function LoadingSpinner() {
  return (
    <div style={loadingContainer}>
      <span style={loadingAnimation} />
    </div>
  );
}

// CREATE ATTRIBUTE
export const Attributes = (props) => {
  const { productAttributesData, setSelectedAttribute, form } = useProducts();
  const [attributesForm, setAttributesForm] = useState({ value: 'none' });

  const onValid = (form) => {
    let checkIsValid = false;

    let checkValue = false;

    if (form.value === 'none' || form.value === '') {
      checkValue = false;
    } else {
      checkValue = true;
    }

    if (checkValue) {
      checkIsValid = true;
    }

    if (!checkValue) {
      checkIsValid = false;
    }

    return checkIsValid;
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    setAttributesForm((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const isValid = onValid(attributesForm);

    if (isValid) {
      const originalData = productAttributesData.filter((item) => item._id === attributesForm.value);
      const updateData = originalData.map((item, idx) => {
        return {
          ...item,
          selectedUnits: [],
          key: generateOtp() + 1
        };
      });

      setSelectedAttribute((prevState) => {
        const oldData = prevState.find((item) => item._id === attributesForm.value);

        if (oldData && oldData._id === attributesForm.value) {
          return prevState;
        } else {
          return [...prevState, ...updateData];
        }
      });

      setAttributesForm({ value: '' });
    }
  };

  return (
    <div className="attributes">
      <AddAttributes form={attributesForm} onChange={onChange} onSubmit={onSubmit} />
      <AddAttributesUnits />
    </div>
  );
};
