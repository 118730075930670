import { Input, Form, Button, notification, Select } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';

import { useTranslation } from 'react-i18next';

const AboutUsForm = ({ data, isNew }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [steps, setSteps] = useState([]);
  const [titles, setTitles] = useState([]);
  const [contents, setContents] = useState([]);
  const [form] = Form.useForm();
  const initialValues = data && data.length > 0 ? { ...data[0] } : null;

  const { t } = useTranslation();

  const [desc, setDescription] = useState(initialValues?.desc);

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const getTextValue = (value) => {
    if (value !== desc) {
      console.log('descsdf', value);
      setDescription(value);
    }
  };

  const onFinish = async (values) => {
    // if (data && data.steps && data.steps.length > 0) {
    //   values.steps = [];
    // } else {
    //   values.steps = initialValues ? initialValues.steps : [];
    // }

    // for (let i = 0; i < steps.length; i++) {
    //   let obj = {
    //     title: titles[i],
    //     content: contents[i],
    //   };

    //   values.steps.push(obj);
    // }

    values.desc = desc;

    if (isNew) {
      values.type = 'about';
      values.url = 'o-nama';

      await Axios.post(`${SERVER_URL}/single-pages`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
    } else {
      await Axios.put(`${SERVER_URL}/single-page/${initialValues._id}`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
    }
    notification.success({ message: 'About us is updated.' });

    navigate('/admin/grocery');
  };

  const deleteStep = (index) => {
    let dataTitles = titles;
    let dataContents = contents;

    dataTitles.splice(index, 1); // 2nd parameter means remove one item only
    dataContents.splice(index, 1); // 2nd parameter means remove one item only

    setTitles(dataTitles);
    setContents(dataContents);
    setSteps(steps.slice(0, -1));
  };

  const handleChangeContents = (e, index) => {
    let con = contents;

    con[index] = e.target.value;

    setContents(con);
  };

  const handleChangeTitles = (e, index) => {
    let titlesArr = titles;

    titlesArr[index] = e.target.value;

    setTitles(titlesArr);
  };

  const handleDeleteStep = async (index) => {
    let steps = initialValues.steps;

    steps.splice(index, 1); // 2nd parameter means remove one item only

    await Axios.put(`${SERVER_URL}/single-page/${initialValues._id}`, { steps }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    notification.success({ message: 'Step is deleted.' });

    navigate('/admin/grocery');
  };

  const customStyle = {
    color: 'white',
  };

  return (
    <div className="title-container">
      <Form initialValues={initialValues} onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p style={customStyle} >{isNew ? t('CREATE') : t('UPDATE')} O NAMA</p>
        </div>
        <Form.Item name="title" label="Naslov">
          <Input type="text" placeholder="naslov" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaTitle" label="Meta Naslov">
          <Input type="text" placeholder="meta naslov" className="input-blog-page" />
        </Form.Item>
        <Form.Item name="metaDesc" label="Meta Opis">
          <Input type="text" placeholder="meta opis" className="input-blog-page" />
        </Form.Item>
        <div className="tiny-blog-page">
          <Editor
            apiKey={apiKey}
            onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
            init={{
              height: 600,
              menubar: true,
              plugins: 'table',
            }}
            value={desc}
          />
        </div>
        {/* <Button style={{ margin: '10px', borderRadius: '10px' }} type="primary" onClick={() => setSteps((curr) => [...curr, {}])}>
          Dodaj korak
        </Button> */}
{/* 
        {steps &&
          steps.length > 0 &&
          steps?.map((item, index) => {
            return (
              <div style={{ display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                <Form.Item label="Title" style={{ marginRight: '5px' }}>
                  <Input
                    onChange={(e) => {
                      handleChangeTitles(e, index);
                    }}
                    value={titles[index]}
                    type="text"
                    className="input-blog-page"
                  />
                </Form.Item>
                <Form.Item label="Content" style={{ marginRight: '5px' }}>
                  <TextArea
                    onChange={(e) => {
                      handleChangeContents(e, index);
                    }}
                    value={contents[index]}
                    type="text"
                    className="input-blog-page"
                  />
                </Form.Item>
                <Button
                  onClick={() => deleteStep(index)}
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    height: '38px',
                    marginRight: '5px',
                    marginTop: '3px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                ></Button>
              </div>
            );
          })}

        {initialValues &&
          initialValues.steps.length > 0 &&
          initialValues.steps.map((item, index) => {
            return (
              <div style={{ display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                <Form.Item label="Title" style={{ marginRight: '5px' }}>
                  <Input disabled value={item.title} type="text" className="input-blog-page" />
                </Form.Item>
                <Form.Item label="Content" style={{ marginRight: '5px' }}>
                  <TextArea disabled value={item.content} type="text" className="input-blog-page" />
                </Form.Item>
                <Button
                  onClick={() => handleDeleteStep(index)}
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    height: '38px',
                    marginRight: '5px',
                    marginTop: '3px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  danger
                ></Button>
              </div>
            );
          })} */}

        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
        {isNew ? t('CREATE') : t('UPDATE')} O NAMA
        </Button>
      </Form>
    </div>
  );
};

export default AboutUsForm;
