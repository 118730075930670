import { Input, Form, Button, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Editor } from '@tinymce/tinymce-react';

import { useTranslation } from 'react-i18next';

const PrivacyPolicyForm = ({ data, isNew }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const initialValues = data && data.length > 0 ? { ...data[0] } : null;

  const [desc, setDescription] = useState(initialValues?.desc);

  const { t } = useTranslation();

  const apiKey = '6jz4sbhxxm5ciyvcnxcx5rwu2rri0tieqwkvnpxjeptnealo';

  const getTextValue = (value) => {
    if (value !== desc) {
      // console.log('descsdf', value);
      setDescription(value);
    }
  };

  const onFinish = async (values) => {
    values.desc = desc;
    if (isNew) {
      values.type = 'privacy';
      values.url = 'politika-privatnosti';
      

      // console.log('valueees', desc);

      await Axios.post(`${SERVER_URL}/single-pages`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
    } else {
      
      await Axios.put(`${SERVER_URL}/single-page/${initialValues._id}`, values, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
    }
    notification.success({ message: 'Privacy policy is updated.' });

    navigate('/admin/grocery');
  };

  const customStyle = {
    color: 'white',
  };

  return (
    <div className="title-container">
      <Form initialValues={initialValues} onFinish={(values) => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p style={customStyle}>{isNew ? t('CREATE') : t('UPDATE')} POLITIKU PRIVATNOSTI</p>
        </div>

        <Form.Item name="title" label="Naslov">
          <Input type="text" placeholder="naslov" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaTitle" label="Meta Naslov">
          <Input type="text" placeholder="meta naslov" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaDesc" label="Meta Opis">
          <Input type="text" placeholder="meta opis" className="input-blog-page" />
        </Form.Item>

        <div className="tiny-blog-page">
          <Editor
            apiKey={apiKey}
            onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
            init={{
              height: 600,
              menubar: true,
              plugins: 'table',
            }}
            value={desc}
          />
        </div>



        <Button htmlType="submit" className="btn btn-primary pl-4 pr-4 d-flex items-center align-center">
          {isNew ? t('CREATE') : t('UPDATE')} POLITIKU PRIVATNOSTI
        </Button>
      </Form>
    </div>
  );
};

export default PrivacyPolicyForm;
