import { Input, Form, Button, notification } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import UpdateFaqForm from '../UpdateFaq/UpdateFaqForm';

const UpdateFaq = (props) => {
  const { user } = useAuth();
  const { token } = user;

  const [data, setData] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const lastIndex = window.location.pathname.lastIndexOf('/');
  const faqId = window.location.pathname.slice(lastIndex + 1);

  useEffect(() => {
    fetchData();
  }, [faqId]);

  const fetchData = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/faq/${faqId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(res.data.item);
      setIsDataFetched(true);
    } catch (error) {
      console.error('Error fetching the data:', error);
    }
  };

  return <div className="title-container">{data && isDataFetched && <UpdateFaqForm isNew={false} data={data} />}</div>;
};

export default UpdateFaq;
