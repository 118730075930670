import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';

export const CreateAttributesAndUnit = ({ createProps, createFormRef, handleOnSubmit, createForm, createFormError, handleOnChange, handleOnBlur, createUnitAttribute, handleOnRemoveUnit, toggleCreateUnitAttribute, setToggleCreateUnitAttribute, setToggleCreate, createBtnLoader, createAttributeUnitFormRef, createAttUnitBtnLoader, createUnitAttributeForm, createUnitAttributeFormError, createAttributeUnitProps }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title="Kreiraj atribut">
      {/* Create Attribute */}
      <form name="create" ref={createFormRef} className="ant-form ant-form-horizontal mb-2" onSubmit={(event) => handleOnSubmit({ event: event, form: createProps })}>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createName">
            <b className="required">Naziv:</b>
          </label>

          <input type="text" placeholder="Name" name="createName" id="createName" data-cy="create-attribute-name-input" value={createForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createFormError.name ? 'input-error' : ''}`} />

          <p data-cy="modal-create-attribute-name-error" className={`error__onblur ${createFormError.name ? 'error' : ''}`}>
            * Ovo polje je obavezno!
          </p>
        </div>

        <div>
          <label className="label mb-1 d-block">
            <b>Vrednosti:</b>
          </label>
        </div>

        <div className="form-group form-group-units relative">
          <div className="units" data-cy="removed-create-attribute-unit-btn">
            {createUnitAttribute && createUnitAttribute.length > 0 ? (
              createUnitAttribute.map((item) => {
                return (
                  <div className="unit-item" key={item._id} data-cy="create-unit-item-btn" onClick={() => handleOnRemoveUnit({ name: 'create-attribute-unit', data: item })}>
                    <span className="icon">
                      <img src="/assets/icons/times-small.svg" alt="Close" />
                    </span>
                    <span className="text">{item.value}</span>
                  </div>
                );
              })
            ) : (
              <p className="units-area">Vrednosti</p>
            )}
          </div>

          {toggleCreateUnitAttribute ? (
            <button type="button" className="btn btn-default" data-cy="toggle-create-minus-attribute-unit-btn" onClick={() => setToggleCreateUnitAttribute(false)}>
              <span className="icon">
                <img alt="Create new Unit" src="/assets/icons/minus.svg" />
              </span>
            </button>
          ) : (
            <button type="button" className="btn btn-default" data-cy="toggle-create-plus-attribute-unit-btn" onClick={() => setToggleCreateUnitAttribute(true)}>
              <span className="icon">
                <img alt="Create new Unit" src="/assets/icons/plus.svg" />
              </span>
            </button>
          )}
        </div>

        {!toggleCreateUnitAttribute && (
          <div className="form-group form-group-sidebar">
            <button data-cy="modal-create-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
              <span className="text">Odustani</span>
            </button>

            {!createBtnLoader ? (
              <button data-cy="modal-create-submit-btn" type="submit" className="btn btn-primary">
                <span className="text">Kreiraj atribut</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">Kreiranje...</span>
              </button>
            )}
          </div>
        )}
      </form>

      {/* Create Unit in Attribute */}
      {toggleCreateUnitAttribute && (
        <form name="create-attribute-unit" ref={createAttributeUnitFormRef} className="ant-form ant-form-horizontal mb-2 p-2" onSubmit={(event) => handleOnSubmit({ event: event, form: createAttributeUnitProps })} style={{ backgroundColor: '#f2f2f2', borderRadius: '6px' }}>
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="createAttributeUnitName">
              <b className="required">Vrednost:</b>
            </label>

            <input type="text" placeholder="Unit" name="createAttributeUnitName" id="createAttributeUnitName" data-cy="create-attribute-unit-name-input" value={createUnitAttributeForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createUnitAttributeFormError.name ? 'input-error' : ''}`} />

            <p data-cy="modal-create-attribute-unit-name-error" className={`error__onblur ${createUnitAttributeFormError.name ? 'error' : ''}`}>
              * Ovo polje je obavezno!
            </p>
          </div>

          <div className="form-group form-group-sidebar">
            <button data-cy="modal-create-attribute-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreateUnitAttribute(false)}>
              <span className="text">Odustani</span>
            </button>

            {!createAttUnitBtnLoader ? (
              <button data-cy="modal-create-attribute-unit-submit-btn" type="submit" className="btn btn-primary">
                <span className="text">Kreiraj vrednost</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">Kreiranje Vrednosti...</span>
              </button>
            )}
          </div>
        </form>
      )}
    </SidebarModal>
  );
};
